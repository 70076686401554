export default {
    "st54": {
      name: "Northern California",
      key: "st54",
      list: ["<span class='number-centers'><span class='number'>22</span> Imaging Centers</span>", "<a class='map-link' href='https://www.radnet.com/northern-california' target='blank'>RadNet Northern California →</a>"],
    },
    "st55": {
      name: "Southern California",
      key: "st55",
      list: ["<span class='number-centers'><span class='number'>133</span> Imaging Centers</span>", "<a class='map-link' href='https://www.radnet.com/imaging-centers/california' target='blank'>View all centers →</a>"],
    },
    "st20": {
      name: "Florida",
      key: "st20",
      list: ["<span class='number-centers'><span class='number'>3</span> Imaging Centers</span>", "<a class='map-link' href='https://www.radnet.com/radiology-imaging-associates/' target='blank'>Radiology Imaging Associates →</a>"],
    },
    "st27": {
      name: "New York",
      key: "st27",
      list: ["<span class='number-centers'><span class='number'>85</span> Imaging Centers</span>", "<a class='map-link' href='https://www.radnet.com/imaging-centers/new-york' target='blank'>View all centers →</a>"],
    },
    
    "st45": {
      name: "New Jersey",
      key: "st45",
      list: ["<span class='number-centers'><span class='number'>21</span> Imaging Centers</span>", "<a class='map-link' href='https://www.radnet.com/new-jersey-imaging-network/' target='blank'>New Jersey Imaging Network →</a>"],
    },
    "st48": {
      name: "Delaware",
      key: "st48",
      list: ["<span class='number-centers'><span class='number'>9</span> Imaging Centers</span>", "<a class='map-link' href='https://www.radnet.com/delaware-imaging-network' target='blank'>Delaware Imaging Network →</a>"],
    },
    "st41": {
      name: "Maryland",
      key: "st41",
      list: ["<span class='number-centers'><span class='number'>55</span> Imaging Centers</span>", "<a class='map-link' href='https://www.radnet.com/imaging-centers/maryland' target='blank'>View all centers →</a>"],
    },
    "st3": {
          name: "Arizona",
          key: "st3",
          list: ["<span class='number-centers'><span class='number'>8</span> Imaging Centers</span>", "<a class='map-link' href='https://www.arizonadiagnosticradiology.com/' target='blank'>View all centers →</a>"]
        }
  };