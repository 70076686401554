import React from 'react'
import './here-for-you.scss'
import pageData from "./pagedata.js"
import radnetLogo from '../../images/logo-radnet.svg'
import wellBeing from '../../images/wellbeing.png'
import Locator from '../Locator'
import {Container} from 'react-bootstrap'
import VideoSection from '../VideoSection'

export default () => (
    <div className="here-for-you">
          <div className="here-for-you__logo section-lg">
                <img src={radnetLogo} width="150" />
            </div>
            <section className="here-for-you__jumbo">
                <div className="nurse" />
                <h2 className="text-center">We're <span>Still</span> <br />Here For You</h2>
            </section>
        <Container style={{maxWidth: `950px`}}>
            <section className="row mt-5">
                <div className="col text-center">
                    <p className="bold">{pageData.main}</p>
                </div>
            </section>
            <section className="here-for-you__needs row mt-5">
                {pageData.needs.map((item, id)=>(
                    <div key={id} className="col-md-4">
                        <div className="here-for-you__needs--box pa4">
                            <p><img className="icon" src={`https://cdnwest.radnet.com/here-for-you/${item.image}`} /></p>
                            <h6>{item.name}</h6>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))}
            </section>
        </Container>

        <section className="here-for-you__together mt-5 pb-5" style={{paddingTop:`88px`}}>
                <div className="section-lg">
                <div className="row">
                    <div className="col-md-4"> 
                        <h2 className="line-after">{pageData.together.title}</h2>
                        <p>{pageData.together.content}</p>
                    </div>
                    <div className="col-md-8 text-center">
                        <p><img src={`https://cdnwest.radnet.com/here-for-you/${pageData.together.image}`} /></p>
                    </div>
                </div>
                <div className="row mt-5">
                    {pageData.together.icons_section.map((item, id)=>(
                        <div className="col-md-4 mt-5" key={id}>
                            <span><img className="icon" src={`https://cdnwest.radnet.com/here-for-you/${item.image}`} /></span>
                            <h6>{item.name}</h6>
                            <p>{item.content}</p>
                        </div>
                    ))}
                </div>
                </div>
        </section>
        <section className="here-for-you__remoteServices mt-5 pt-5 section-lg">

            <div className="row">
                <div className="col-md-4">
                    <h2 className="line-after">
                        {pageData.remoteSimple.title}
                    </h2>
                </div>
                {pageData.remoteSimple.steps.map((item, id)=>(
                    <div className="col-md-4" key={id}>
                        <p><span className="icon-circle">{id+1}</span></p>
                       <p>{item}</p>
                    </div>
                ))}
            </div>
            <div className="mt-5 mb-5">
                <p><img src={`https://cdnwest.radnet.com/here-for-you/${pageData.remoteSimple.image}`} /></p>
            </div>

        </section>
        <section className="here-for-you__covidImpact mt-5 pt-5 section-lg d-none" style={{marginBottom:`200px`}}>
            <div className="row">
                <div className="col-md-4">
                    <h2 className="line-after">{pageData.covidImpact.title}</h2>
                    
                </div>
                <div className="col-md-8">
                    {pageData.covidImpact.stats.map((item, id)=>(
                        <div key={id}>
                            <span className="stats">{item.name}</span>
                            <p>{item.content}</p>
                        </div>
                    ))}
                </div>
                <div className="people-walking"><img src={`https://cdnwest.radnet.com/here-for-you/${pageData.covidImpact.image}`} /></div>
            </div>
        </section>
        <section className="here-for-you__wellbeing mt-5 pb-5 section-lg">
            <div className="row">
                <div className="col-md-4">
                    <h2 className="line-after">{pageData.wellBeing.title}</h2>
                    <p>{pageData.wellBeing.content}</p>
                </div>
                <div className="col-md-8 text-center">
                    <img src={wellBeing} width="540" />
                </div>
            </div>
        </section>
        <section className="here-for-you__videos mt-5 section-lg">
            <div className="row text-center">
                <div className="col">
                    <h2>Featured Videos</h2>
                    <VideoSection />
                </div>
            </div>
        </section>
        <section className="here-for-you__radnetdifference mt-5 pb-5 section-lg">
            <div className="row text-center">
                <div className="col-md-12"><h2>{pageData.radnetDifference.title}</h2></div>
                <div className="col-md-12">
                    <p className="pt-5" style={{maxWidth:`615px`, marginLeft:`auto`, marginRight:`auto` }}>{pageData.radnetDifference.content}</p>
                </div>
            </div>
        </section>
        <section className="section-lg">
            <Locator />
        </section>
        
    </div>

)