import React, { useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import _ from "lodash-es"
import 'mapbox-gl/dist/mapbox-gl.css';
import './locator.css'

//Hide API Key in Node
mapboxgl.accessToken = `pk.eyJ1IjoidmlqYXlyYWRuZXQiLCJhIjoiY2s1eWs2OHo3MmhtODNlbWpmcjYzcHJuayJ9.pp4YO_NmgZbILRLZCxTWBQ`

class LocatorComponent extends React.Component {
    state = {
        map: undefined
    }

    componentDidUpdate(prevProps){
        if(this.props.locations !== prevProps.locations){
            this.drawMap()
        }
    }

    componentDidMount(){
        this.drawMap()
    
    }

    addMarkers(item, map){
        let htmlString = '<p><b>'+item.name+'</b></p>';
        htmlString +='<p>'+item.address+' '+item.postal+','+item.city+' '+item.state.abbreviation+'</p>';
        htmlString +='<p><b>Services: </b>';
        var service = "";
        Object.keys(item.services).forEach(function(key) {
            service += ' '+item.services[key].name+',';
        });
        service = service.replace(/,\s*$/, "");
        htmlString += service+'</p>';
        item.numbers.forEach((itemy, key)=>{
            if(itemy.type === 'phone'){
                htmlString += '<p><b>Phone:</b> <a href="tel:'+itemy.number+'">'+itemy.number+'</a></p>';
            }else if(itemy.type === 'fax'){
                htmlString += '<p><b>Fax:</b> '+itemy.number+'</p>';
            }
        });
        htmlString += '<a class="button" href="'+item.appointment_url+'" target="_blank"> Schedule </a>';
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlString);
        let coords = [item.longitude, item.latitude]
        let el = document.createElement('div');
        el.classList.add('marker');
        el.style.backgroundImage =
        'url(https://cdnwest.radnet.com/maps/radnet-pin.svg)';
        el.style.width = '42px';
        el.style.height = '42px';
        new mapboxgl.Marker(el).setLngLat(coords)
        .setPopup(popup)
        .addTo(map);
    }

    drawMap(){
        //Default coords for all 35.790863, -114.640892
        let baseCoords = this.props.locations && this.props.locations.length > 0 && this.props.locations.length < 120 ? [this.props.locations[0].longitude, this.props.locations[0].latitude] : [-114.640892, 35.790863]
        let baseZoom = this.props.locations && this.props.locations.length > 0 && this.props.locations.length < 120 ? 10 : 5.2;
        //console.log(this.props.locations);
        let map = document.getElementById('map');
        let myMap = new mapboxgl.Map({
                container: map,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: baseCoords,
                zoom: baseZoom
            })
            
        if(this.props.locations){
            this.props.locations.forEach((item, key)=>{
                // create a DOM element for the marker
                this.addMarkers(item, myMap)
            })
        }else {
            let locations = []
            fetch(`https://stage.radnet.com/locator/search/json?locator_search=CA`)
            //fetch(`https://www.radnet.com/locator/search/json`)
            .then(res => res.json())
            .then(res => {
                //console.log(res.matched)
                locations = res.matched
                locations.forEach((item, key)=>{
                    this.addMarkers(item, myMap)
                })
            })
            .catch(err => {
                console.log(err)
            })

        }

        this.setState({map: myMap})
    }

    render(){
        return (

            <div id="map" style={{height:`480px`}} className="mb-5"></div>

        )    
    }

}

export default LocatorComponent