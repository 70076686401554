import React, {Component} from "react"
import classMaps from "../data/mapSvg"
import MapSVG from "./mapSvg"
import * as d3 from "d3"
import $ from "jquery"

class Map extends Component {
    constructor(){
        super()
    }
    componentDidMount(){

        let $svgH = $("#usa-map").height();
        $(".map-container").height($svgH);

        $(window).resize(function () {
            if (window.innerWidth < 580) {
              $("#tooltip").hide();
            }
            $svgH = $("#usa-map").height();
            $(".map-container").height($svgH);
          })

        let paths = d3.select("#usa-map").selectAll('path')
        let c_i = 1;
        paths.each(function (d, i) {

            let classSelected = d3.select(this).attr('class');
            let stateSelected = classMaps[classSelected];
            let isState = (typeof stateSelected == "object") ? true : false;
    
            if (isState) {
              let p_x = this.getBBox().x,
                p_y = this.getBBox().y;
              // console.log(this.getBBox(), stateSelected.key, stateSelected.name)
              //get approx location on svg
              d3.select(this).style('fill', 'rgba(183, 0, 0, 0.18)');
              console.log(stateSelected.name.indexOf("California"))
    
    
              if (stateSelected.name.indexOf("California") > 0) {
    
                let $d = $("<div></div>");
                $d.addClass('glowing-orb').addClass("california" + (c_i).toString());
                $(".map-container").append($d);
                c_i++;
    
              } else {
                let $d = $("<div></div>");
                $d.addClass('glowing-orb').addClass(stateSelected.name.toLowerCase().replace(/\s/g, ''));
                $(".map-container").append($d);
    
              }
            }
    
    
            //console.log(this)
            $(this).hover(function () {
              let path = $(this)[0];
    
              if (isState) d3.select(this).style('fill', 'rgba(183, 0, 0, 0.58)');
            }, function () {
              let path = $(this)[0];
              if (isState) d3.select(path).style('fill', 'rgba(183, 0, 0, 0.18)');
            }).css('cursor', 'pointer');
    
    
            d3.select(this).on('click', function () {
              $("#tooltip").hide();
              let mouse = d3.mouse(this);
              console.log(mouse);
              let classSelected = d3.select(this).attr('class');
              if (classSelected) {
                toolTip(classSelected, mouse);
              }
    
            });
    
          });

          function toolTip(classSel, mouse) {
            let x = mouse[0];
            x = ((x / 881) * 100);
    
            let y = (mouse[1] + 100) / 1140 * 100;
            let stateSelected = classMaps[classSel];
            let isState = (typeof stateSelected == "object") ? true : false;
    


            if (isState){

                let $h3 = $("#tooltip h3"),
                $ul = $("#tooltip ul");
                    
                $ul.html("");
                    
                for (var i = 0; i < stateSelected.list.length; i++) {
                    $ul.append("<li>" + stateSelected.list[i] + "</li>");
                }

                $h3.html(stateSelected.name);

                if(window.innerWidth > 540) {
                    $("#tooltip").css({
                    'top': y + "%",
                    'left': x + "%"
                    }).fadeIn();
                }else {
                    $("#tooltip").css({
                        'top': "-60px",
                        'left': "50%",
                        'marginLeft': "-130px"
                        }).fadeIn();
                }
            }
          }

    }
    
    render(){
        return (

            <div className="map-container">
                <div id="usa-map">
                    <MapSVG />
                </div>
                <div id="tooltip">
                    <h3>City Name</h3>
                    <ul>
                        <li>Fact one</li>
                        <li>Fact two</li>
                        <li>Fact three</li>
                    </ul>
                </div>
                <div className="glowing-orb california3">&nbsp;</div>
            </div>
        )
    }
}

export default Map