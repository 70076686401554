import React from "react"

export default () => (
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 40 792 612" style={{enableBackground:"new 0 0 792 612"}}>
              <radialGradient id="SVGID_1_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st0" d="M346.9,341.7c-1,7.4-0.8,15.1-1.5,22.7c-0.2,1.9-0.2,3.8,0,5.7c0.2,2.8,4.1,5.5,6.8,5.5c1.7,0,4.3-1.1,4.6,2.2
	c0,0.5,0.5,1,1,1c2.5,0,4.6,1.7,7.2,1.8c1.7,0.1,3.2,1,5.2,0.4c2.1-0.6,3.5,1.9,4.1,3.2c1,2.4,2.3,1.9,3.6,1.1
	c1.7-1,2.8-0.7,4.1,0.7c1.2,1.3,3,1.8,4.6,2.3c0.6,0.2,1.4,0.1,1.6-0.4c1.7-3.6,4.4-1.2,6.7-1.1c1.1,0.1,2.1,0.9,3.2,1.3
	c1.3,0.5,2.8,0.6,3.7-0.6c1-1.4,2.2-1.6,3.7-1.6c2.4,0.1,4.7-1,7.1,0c0.3,0.1,0.8,0.1,1,0c2.6-2.1,4.1,0.3,5.8,1.5
	c2.6,1.7,5.8,2,8.3,3.9c0.2,0.2,0.6,0.1,1,0.1c3.7-0.3,3.6-0.3,3.7,3.5c0.1,7.5,0.4,15,1,22.4c0.1,1,0.3,1.8,1,2.5
	c0.9,1.1,1.6,2.3,1.8,3.8c0.2,1.9,1.4,3.3,2.3,4.9c2.3,4.1,3.8,8.5,1.2,13.2c-0.8,1.5-1.5,3.1-1.2,4.9c0.6,4-0.8,7.8-1.6,11.7
	c-0.1,0.6-0.4,1.1-1,1.2c-2.3,0.4-4.2,2.1-6.5,2.7c-4.3,1.1-6.7,4.7-9.8,7.3c-5.1,4.3-10.5,7.8-16.5,10.6c-4.5,2.1-8.2,5.5-12.1,8.3
	c-2.4,1.7-4,4.8-5.5,7.6c-2.2,4.1-4.7,8-5.2,12.9c-0.4,3.1,0.9,5.7,1.6,8.5c0.6,2.6,0.5,5.3,2,7.7c0.6,1-0.7,1.3-1.3,1.8
	c-0.9,0.9-1.7,0.6-2.7,0.1c-1.7-1.1-3-2.9-5.3-3c-1.2-0.1-2.3,0.1-3.5,0.1c-1,0-2.2-0.2-2.8-0.7c-3.4-2.9-7.9-3.3-11.7-5.3
	c-1.8-1-2.9-2.2-3.3-4.3c-0.4-2.2-0.7-4.7-3-6.1c-0.3-0.2-0.6-0.6-0.5-0.8c0.8-2.7-1.5-5.2-0.7-7.9c0.4-1.3,0-2.5-1.2-3
	c-3.9-1.5-5.4-5-7.3-8.2c-0.7-1.2-1.2-2.5-2.7-3.1c-0.9-0.3-1.1-1.2-1.3-2c-0.9-3.2-2.9-6.1-3.6-9.3c-1.5-6.7-7.2-10.2-10.7-15.3
	c-0.7-1-1.8-1.6-3.1-1.8c-4-0.6-8.2-0.3-11.8-2.4c-0.3-0.2-0.7-0.2-1-0.1c-3.2,1.4-7,1.9-8.1,6.2c-0.8,3.1-3.1,5.3-4.8,7.8
	c-0.7,1-1.5,1-2.4,0.6c-1.1-0.5-2.3-1-3.3-1.8c-4.4-3.4-9.6-5.7-13.4-9.8c-2.3-2.5-3.5-5.2-3.5-8.5c-0.2-6.6-2.7-11.8-8.5-15.4
	c-3.5-2.3-5.4-6.3-8.3-9.2c-2.5-2.5-4.6-5.2-6.4-8.1c-0.8-1.3-3.3-2-2.4-3.9c0.8-1.9,2.8-0.6,4.3-0.4c13.2,1.3,26.3,2.7,39.5,4
	c2.3,0.2,4.7,0,7,0.1c2,0.1,2.8-0.9,2.8-2.7c0.1-7.5,1-14.9,1.6-22.4c0.4-5.3,1.1-10.6,1.7-15.9c0-0.4,0.2-0.8,0.2-1.2
	c-0.4-6.9,1.1-13.6,1.1-20.4c0-5.1,1.2-10.1,1-15.2c0-1.6,1.1-1.9,2.4-1.8c3.7,0.2,7.3,0.3,11,0.6c4.2,0.4,8.4,1,12.7,0.8
	c4.8-0.2,9.5,0.5,14.2,0.5C347.9,337.4,346.4,339.9,346.9,341.7z" />
              <radialGradient id="SVGID_2_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st1" d="M213.1,178.9c-0.5-1-0.7-1.6-1-2.2c-1.2-2.5-2.7-2.7-4.5-0.6c-1.1,1.3-2.3,1.2-3.6,0.7
	c-1.7-0.7-3.4-0.9-5.2-0.5s-3.5,0.2-5.2-0.2c-0.7-0.2-1.4-0.4-2.2,0c-1.6,0.9-2.7,0.1-2.3-1.7c0.7-2.8-0.5-4.4-2.5-6
	c-1.2-0.9-1.8-2.4-2.1-3.9c-0.7-3.2-1.7-6.3-1.7-9.6c0-2.8-1.1-3.1-3.4-1.7c-1.1,0.7-2.1,2.4-3.7,1.3c-1.7-1.2-1.7-3-1-4.9
	c1.1-2.8,2.1-5.6,3.3-8.4c0.7-1.5,1.3-3.1,1.7-4.7c0.6-2.5-1.8-2.3-2.9-3.1c-2.9-2.2-3.7-5.4-5-8.4c-0.8-1.9-1.5-3.8-3.7-4.7
	c-1.5-0.6-1.8-2.4-1.3-3.9c0.8-2.3,0.3-4.4-0.9-6.4c-1.1-1.7-1.1-3.3-0.5-5.2c1.1-3.9,1.8-8,2.6-12c0.3-1.5,1-2,2.6-1.7
	c15,3,30,6.1,45.1,8.8c10.1,1.8,20.2,3,30.4,4.4c7.8,1,15.5,2.1,23.3,3.1c9.5,1.2,19,2.2,28.5,3.3c2.6,0.3,5.3,0.6,7.9,0.8
	c1.8,0.2,2.4,1.1,2.3,2.8c-0.4,4.7-0.8,9.4-1.2,14.2c-0.4,4.9-1,9.8-1.3,14.7c-0.3,6.9-1.5,13.7-1.8,20.6c-0.2,3.8-0.5,7.6-0.7,11.4
	c-0.1,1-0.1,2-0.4,3c-0.7,3.3-1,3.3-4.4,2.9c-8.1-0.9-16.2-2.2-24.3-2.2c-4.2,0-8.3-1-12.3-1.7c-8.5-1.5-17.2-1.8-25.7-3.4
	c-6.1-1.2-12.5-1-18.5-2.7c-1.8-0.5-2.8,0-3,2C214.2,174.8,214.2,176.7,213.1,178.9z" />
              <radialGradient id="SVGID_3_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st2" d="M244.1,413.7c-7.4-0.5-14.4-1.8-21.5-2.9c-1.8-0.3-2.5,0.2-2.6,1.9c0,0.2-0.1,0.3-0.1,0.5
	c-1.1,5.5-1.1,5.5-6.9,4.8c-5.4-0.7-5.4-0.7-4.9-6c1.3-12.5,3.5-24.9,5.2-37.3c1-7.5,1.7-15,2.9-22.5c1.6-10.8,3.4-21.5,4.4-32.4
	c0.2-2.1,1-2.5,2.9-2.2c9.6,1.8,19.3,2.3,28.9,3.8c12.4,1.9,25,2.3,37.5,3.5c4.2,0.4,8.4,0.9,12.7,1c1.4,0,2.3,0.5,2.2,2
	c-0.2,2.5,0,4.9-1,7.5c-1.2,2.9-0.7,6.4-1,9.6c-1.1,12.8-1.3,25.6-2.8,38.3c-0.8,6.7-1.5,13.4-1.7,20.1c-0.1,2.7-0.5,5.3-0.5,8
	c0,1.9-0.9,2.6-2.8,2.7c-5.9,0.2-11.8-0.8-17.7-1.3c-8.9-0.7-17.7-1.8-26.6-2.7c-1.1-0.1-2.1-0.3-3.2-0.4
	C244.3,409.3,243.4,410.3,244.1,413.7z" />
              <radialGradient id="SVGID_4_" cx="396.2129" cy="323.7752" r="357.3538"  gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st3" d="M219.5,319.5c-1,11.1-3.1,22.1-4.5,33.2c-1.1,8.8-2.2,17.7-3.4,26.5c-1.4,10.1-2.8,20.1-4.2,30.2
	c-0.3,1.8-0.7,3.6-0.7,5.4c-0.1,1.7-0.8,2.1-2.4,1.8c-8.3-1.4-16.7-2.2-25-3.6c-4.7-0.8-8.2-3.6-12-5.7c-13.8-7.8-27.3-16-40.9-24.1
	c-0.8-0.5-1.8-0.8-1.4-2c0.4-1.3,1.4-2.1,2.8-2.2c2.1-0.1,3.3-1.1,3.6-3.1c0.2-1.1,0.1-2.1-1.1-2.7c-1.7-0.9-1.9-5.1-0.3-6.2
	c2.3-1.5,3.2-3.6,3.7-6.2c0.5-2.7,1.8-4.9,4.7-6c2.5-0.9,2.7-2.2,1.3-4.4c-1.8-2.9-2.2-6.4-3.5-9.6c-0.2-0.3-0.1-0.9,0-1.2
	c2.5-5.4,0.9-11.3,1.9-17c0.2-1.2-0.4-2.7,1.2-3.2c1.6-0.5,3.1,0.2,4.1,1.2c0.9,1,1.7,2.1,3.1,1.5c1.5-0.7,2.4-2,2.6-3.7
	c0.6-3.5,1.3-7,1.9-10.6c0.3-1.7,1-2.2,2.7-1.9c14.3,3,28.8,5.3,43.3,7.6c6.8,1.1,13.6,2.5,20.5,3.2
	C219.5,317,219.7,317.4,219.5,319.5z" />
              <radialGradient id="SVGID_5_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st4"
                  d="M78.7,250.6c1.1-4.4,2.2-8.9,3.3-13.3c2.9-11.2,5.9-22.4,8.8-33.6c0.8-3.2,0.9-3.4,4-2.5
	c10.4,3,20.9,5.5,31.5,7.7c10.6,2.1,21.1,4.6,31.7,6.9c2.3,0.5,4.6,0.8,6.9,1.3c1.7,0.3,2.2,1.2,1.9,3.1c-2,10-4,19.9-5.9,29.9
	c-3.9,20.4-7.8,40.7-11.7,61.1c-0.4,2.4-0.8,4.7-1.2,7.1c-0.2,1.2-0.7,2.3-1.8,2.8s-1.5-0.7-2.1-1.3c-1.1-1-2.5-1.6-3.8-1.6
	c-1.8-0.1-3.2,0.9-3.2,3c0.1,4.4-0.8,8.8-0.5,13.2c0.1,1,0.3,2.4-0.7,2.7c-1.1,0.4-1.6-1.1-2-1.8c-2.9-5-6.3-9.6-9.5-14.4
	c-4.7-7.2-9.4-14.3-14.2-21.5c-7.2-10.9-14.5-21.8-21.8-32.6c-2.7-4.1-5.5-8.1-8.4-12.1C79.1,253.4,78.8,252.2,78.7,250.6z" />
              <radialGradient id="SVGID_6_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st5" d="M312.5,326c-7-0.6-14.1-1.3-21.1-1.9c-5.9-0.5-11.7-1.3-17.6-1.6c-7.1-0.4-14.2-1.1-21.3-2.1
	c-9.1-1.3-18.3-2.4-27.5-3.5c-0.7-0.1-1.5-0.3-2.2-0.4c-1.4-0.2-1.7-1-1.5-2.3c1-5.7,2-11.5,2.5-17.3c0.9-10.9,3.3-21.7,4.4-32.6
	c0.5-5,1.4-10,2.1-15.1c0.3-2.4,1.2-3,3.8-2.7c10.2,1.2,20.4,2.8,30.7,3.9c8.9,1,17.9,1.6,26.8,2.7c5.2,0.6,10.4,0.8,15.7,1.1
	c4.6,0.3,9.1,1.2,13.7,1c1.6-0.1,2,0.9,1.9,2.3c-0.4,5.2-0.9,10.4-1.3,15.6c-0.1,1-0.2,2-0.1,3c0.5,7.6-0.9,15.1-1.4,22.6
	c0,0.5-0.1,1-0.1,1.5c0.7,7.8-1.2,15.6-1,23.4c0,1.6-0.7,2.3-2.3,2.2C315.1,325.8,313.8,325.9,312.5,326
	C312.5,325.9,312.5,325.9,312.5,326z" />
              <radialGradient id="SVGID_7_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st6" d="M74.4,505.7c-2.1-4.5-3.6-5.2-8.4-4.4c-0.4,0.1-1.1,0.1-1-0.3c0.9-1.6-0.9-3.6,0.9-4.9c0.8-0.5,0.7-1.1,0.7-1.9
	c-0.1-2.3-0.1-4.2-3.5-3.2c-2.3,0.7-4.3-0.1-4.6-3.1c-0.1-0.6-0.5-1.3-0.8-1.8c-0.7-1.1-0.7-1.9,0.2-3.1c0.7-1,2.6-2.4-0.2-3.6
	c-1.2-0.5-0.8-4.2,0.6-5.5c2.3-2.3,5.3-3.8,7.2-6.5c0.7-0.9,2.1-1.4,2.9,0.2c1,1.9,2.4,1.6,3.8,0.7c1.1-0.7,1.9-0.9,3.2-0.5
	c2,0.5,3.6-0.3,4.2-2.5c0.5-1.8,0.7-3.6,1.5-5.3c0.8-1.7-0.8-2.3-1.8-1.9c-4,1.9-7.4-1.1-11-1.5c-2.7-0.3-4.5-2.4-3.4-5.2
	c0.6-1.6,0.7-3.1-1.1-4.1c-0.6-0.3-1.1-1.1-0.8-1.9c0.3-0.7,1.1-0.8,1.7-0.9c2.6-0.5,5.4-0.2,7.9-1.5c0.8-0.4,2-0.3,2.9,0.2
	c1.5,0.8,0.2,1.7,0,2.5c0,0.2-0.2,0.6-0.1,0.7c1,1.6,2.8,1.9,4.4,2.6c1,0.4,2.1,0.6,3.1-0.2c0.6-0.5,0.3-1.1,0.1-1.6
	c-0.7-1.7-2.1-3.3-1.7-5.4c0-0.1,0-0.2-0.1-0.2c-3.6-3.3-2.4-8.9-6-12.3c-1.2-1.1,0.2-2.9,2.2-2.9c0.8,0,1.6,0.2,2.5,0.4
	c3,0.5,5.1-1,6.7-3.4c1.3-2,2.9-3.3,5.5-3.2c0.6,0,1.3-0.2,1.9-0.6c1.9-1.2,3.9-1.7,6.2-1.4c1.1,0.1,2.1,0,3.1-0.8
	c2.2-1.7,5.4-0.9,6.1,1.8c0.4,1.4,0.9,1.7,2.2,1.2c1.2-0.5,2.4-0.2,2.6,1.4c0.4,2.3,2,3,4,2.8c2.4-0.1,4.2,0.9,6.3,1.9
	c3.5,1.7,7.1,3.7,11.4,2.4c2.4-0.7,6.9,3.3,6.9,5.9c0,21.1,0,42.2,0,63.3c0,4.2,0.8,4.8,4.9,3.5c0.7-0.2,1.5-0.5,1.8,0.2
	c1.1,2.7,3.7,4.2,5.5,6.4c0.2,0.3,0.4,0.5,0.6,0.8c0.5,0.7,0.4,2.2,1.7,1.7c1.1-0.5,2.7-0.7,3-2.3c0.2-1.2,0.7-2.2,1.8-2.8
	c1.4-0.8,2.3-0.7,3.3,0.8c1.5,2.2,3,4.6,5.5,5.9c0.7,0.4,1.2,1.2,1.6,1.9c1.9,4,5,7.2,7.3,11c1.1,1.8,3.2,2.2,5.2,2.7
	c1.9,0.4,3.2,1.2,3.7,3.2c0.7,2.6,0.6,5.1-1,7.4c-0.5,0.7-1.1,0.5-1.3-0.2c-0.7-2.2-2.7-2.5-4.3-3.4c-0.4-0.2-0.8-0.4-1.1-0.1
	c-0.4,0.3-0.2,0.8-0.1,1.2c0.1,0.4,0.3,0.8,0.3,1.2c0.1,0.9,0.7,2.4-0.4,2.5c-1.2,0.1-2.9,1.8-3.9-0.6c-0.3-0.8-1-1.5-0.9-2.3
	c0.4-2.4-2-3.5-2.5-5.5c-0.1-0.7-1-0.7-1.7-0.3c-1.6,0.8-2.7,0-3.5-1.4c-2.2-3.5-4.7-6.8-6.6-10.5c-2.6-5.1-7-8-11.7-10.7
	c-1-0.6-2.1-0.9-1.6-2.6c0.3-1-0.6-1.1-1.3-0.8c-2.1,0.9-3.9,0-5.7-0.9c-1.1-0.5-2.4-1.2-3.5-1.2c-3.8,0.1-7.1-0.7-10.4-2.8
	c-1.4-0.9-2.3-1.8-2.9-3.1c-0.7-1.5-1.7-2.7-3.5-2c-1.7,0.7-4,0.8-3.9,3.7c0.1,3.1-0.4,3.4-3.4,2.5c-1.9-0.5-2.8,0.5-3.8,1.6
	c-1.4,1.7-3.1,3-5.3,3.4c-0.7,0.1-1.7,0.3-2-0.5c-0.4-1.1,0.8-1,1.4-1.4c1-0.6,2.8-1.2,0-1.8c-1-0.2-0.3-1.1-0.1-1.7
	c0.8-1.7,2.2-3,2.5-5.1c0.1-1,1.9-1.7,3.4-1.4c0.9,0.2,2,0.8,2.2-0.5c0.1-1.1-0.9-1.9-2.1-2c-1.6-0.2-3.1,0.2-4.2,1.2
	c-2.1,1.7-4.1,3.5-5.1,6c-0.6,1.6-1.6,2.6-3.4,2.9c-0.9,0.1-2,0.6-2.7,1.2c-1,0.9-1.6,2,0.1,3c2,1.2,1.2,2.3,0,3.5
	c-3.2,3.2-7.7,4.3-11.2,6.9c-2.4,1.7-5.2,2.1-7.9,2.8c-1.4,0.4-3.5,0.3-2.7,3c0.2,0.8-0.8,1.3-1.8,1.1c-2.7-0.4-5.3-0.5-7.7,1
	c-0.3,0.2-0.9,0.4-1.2,0.3c-3-0.9-5.7,0.5-8.6,0.9c-0.5,0.1-1.1,0.2-1.4,0.5c-1.3,1.3-2.6,1.2-4.1,0.6c-1-0.4-2-0.4-2.9-0.1
	c-0.7,0.3-1.6,0.6-1.9-0.3c-0.3-0.9,0.7-1.2,1.3-1.5c1.2-0.6,2.5-0.6,3.7-0.3c2.3,0.6,4.2-0.2,6.1-1.4c2.3-1.5,4.7-3,7.6-1.6
	c0.7,0.3,1.3,0.1,1.8-0.4c2-2.2,4.8-2.5,7.5-3c0.2,0,0.7,0.1,0.7,0c1.2-2.9,4.8-2.9,6.2-5.7c1.1-2.1,1.6-4.6,3.5-6.3
	c0.4-0.3,0.7-0.8,0.4-1.3c-0.3-0.5-0.9-0.4-1.3-0.2c-1.3,0.3-2.1,0.3-3.3-1C75.2,502,75.3,504.8,74.4,505.7z" />
              <radialGradient id="SVGID_8_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st7" d="M301.8,186.1c-0.7,7.6-1.7,15.1-1.8,22.7c-0.1,7.4-1.9,14.7-1.8,22.1c0.1,6.3-1.5,12.5-1.8,18.9
	c-0.1,2.2-1.3,2.7-3.1,2.5c-4.1-0.4-8.3-0.8-12.4-1.2c-6.3-0.6-12.6-1.1-18.9-1.8c-5.1-0.6-10.2-1.4-15.3-2c-7.7-1-15.4-1.9-23-3
	c-5.7-0.8-11.4-1.7-17-2.4c-1.7-0.2-1.9-0.9-1.7-2.5c1.5-11.4,4.2-22.6,5.5-34c1.2-10.3,3.5-20.4,4.8-30.6c0.4-3.2,0.5-3,3.6-2.5
	c6.6,1.1,13.3,1.9,20,2.9c9.4,1.3,18.9,1.8,28.2,3.8c5.2,1.1,10.5,0.5,15.7,1.2c5.8,0.8,11.6,1.3,17.3,2.1
	C302.8,182.5,301.3,184.8,301.8,186.1z" />
              <radialGradient id="SVGID_9_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st8" d="M74.4,123.4c0,0.3,0,0.7,0,1c0.2,1.5-1.6,3,0.5,4.5c2.7,1.9,5.5,3.7,8.7,2.5c3.3-1.3,6-0.1,8.7,1.1
	c2.3,1,4.8,1.2,7.1,1.8c6.5,1.7,13,0,19.4,0.5c6.7,0.5,12.8,2.8,19.3,3.8c1.5,0.2,2.9,0.7,4.4,1.1c2,0.4,4,0.8,4.3,3.4
	c0,0.1,0.1,0.2,0.1,0.2c1.3,1.3,3.2,2.4,2.5,4.5c-0.6,1.6-2,2.9-2.9,4.3c-1.4,2.3-3.8,4-4.3,6.9c-0.1,0.8-1,1-1.6,1.4
	c-2.3,1.6-4,3.7-4.6,6.5c-0.3,1.6-0.7,3,1.6,3.7c1.5,0.4,0.9,2.1,0.4,3.1c-2.7,6-3.5,12.5-5.2,18.7c-1.2,4.5-2.2,9.1-3.3,13.6
	c-0.4,1.8-1.1,2.4-3.1,2c-8.3-1.5-16.5-3.4-24.6-5.5c-12.5-3.2-24.9-6.8-37.3-10.2c-8-2.2-16-4.6-23.9-6.9c-3.1-0.9-3.8-2.4-3.3-5.6
	c0.5-2.9,0.8-5.8,1.1-8.7c0.1-0.8,0.3-1.6,0.8-2.3c6.7-9.2,11.2-19.4,15.3-29.9c2.7-6.8,5.3-13.6,7.7-20.5c1.1-3.1,4.6-3.8,6.7-1.4
	c0.3,0.4,0.7,0.7,1.2,0.8C73.7,118.6,74.4,119.5,74.4,123.4z" />
              <radialGradient id="SVGID_10_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st9"
                  d="M216.6,315.6c-5.9-0.1-12.3-1.9-18.9-2.9c-14.3-2.2-28.6-4.6-42.8-7.4c-3.6-0.7-3.7-0.7-2.9-4.4
	c3.1-15.7,6.4-31.3,9.2-47c2-11,4.3-21.9,6.3-32.9c0.6-3.2,0.6-3.2,3.8-2.6c6.3,1.3,12.7,2.1,18.9,3.3c4.5,0.9,9,1.2,13.5,2.1
	c1.7,0.4,2.5,0.9,1.9,2.8c-1.3,4.4-0.9,9.1-2,13.6c-0.3,1.3,0.1,2.2,1.7,2.4c7.4,0.9,14.8,2,22.2,3c1.5,0.2,2.1,0.8,1.9,2.3
	c-1.6,11.6-2.9,23.3-5,34.8c-1.6,8.9-1.6,17.9-3.4,26.7c-0.3,1.4-0.5,2.8-0.7,4.2C220.2,315.4,219.2,316.2,216.6,315.6z" />
              <radialGradient id="SVGID_11_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st10" d="M480.8,130.9c-2.5,2-5.3,2.8-7.9,4c-4.6,2.1-8.4,4.8-11.3,9.1c-2.5,3.7-6.1,6.3-9.6,9.1
	c-1.8,1.4-2.9,3-2.6,5.3c0.1,0.7-0.1,1.5,0,2.2c0.4,3.5-0.3,6.2-3.9,8c-2.1,1-2.4,3.7-3,5.8c-0.2,0.6,0.3,1,0.9,1.3
	c2,1,2.1,2.4,1.2,4.3c-1.6,3.3-0.1,6.7,0.1,10.1c0.1,1.2,1.5,2.2,2.6,2.5c4,1.3,6.9,3.8,9.5,7c1.1,1.3,3,2.1,4.6,3
	c2.2,1.3,3.2,3.3,3.4,5.8c0.1,1.4-0.3,2.2-1.8,2.3c-6.5,0.3-13,1-19.4,0.9c-13.8-0.1-27.5,0.9-41.2,0.6c-3.5-0.1-3.5,0-3.5-3.5
	c0-8.4,0-16.8,0-25.3c0-2.3-0.4-4.1-2.4-5.6c-2.7-2.1-2.6-2.4-0.2-4.7c0.5-0.5,1-1,1-1.7c0.1-3.3,0.8-6.8-0.5-9.9
	c-2.1-4.8-1.9-9.9-2.3-15c-0.3-4.1-0.3-8.2-2.2-12.1c-1.8-3.7-1.7-7.8-1.2-11.8c0.3-1.8,0.3-3.5-0.3-5.2c-0.8-2.3,0.2-2.7,2.1-2.4
	c6.1,0.9,12.3,0.2,18.5,0.5c2.4,0.1,3.5-0.7,3.1-3.1c-0.1-0.4-0.1-0.8,0-1.2c0.2-0.9-0.5-2.2,1.1-2.3c1.3,0,2.2,0.5,2.5,2
	c0.4,2.1,0.8,4.2,1.2,6.4c0.4,2.1,1.6,2.8,3.6,3.4c2.6,0.7,5.6,0,7.7,2.5c0.8,0.9,2.9,0.9,3.9-0.3c2.1-2.5,4.4-1.5,6.6-0.7
	c2.1,0.8,4.1,1.9,6,3c0.9,0.5,1.8,1,2.8,0.7c0.9-0.2,1.4,0.1,1.9,0.8c1.3,1.7,3.3,2.3,5,3.3c2.5,1.5,4.8-0.1,6.8-1.6
	c1.1-0.8,2.2-1.7,3.1,0.4c0.4,0.8,1.3,0.5,2,0.5c2.9,0,5.9-1.2,8.5,1.4C478.1,131.5,479.5,130.1,480.8,130.9z" />
              <radialGradient id="SVGID_12_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st11" d="M188.5,172.6c-0.6,1.9-0.4,3.6,0.9,5.1c0.7,0.8,1.4,1.3,2.2,0.1c0.4-0.5,0.8-1,1.4-0.7
	c3.8,1.3,7.8-0.8,11.6,0.9c1.4,0.6,2.9,0.3,3.9-1.3c0.5-0.7,1.5-1.4,2-0.4c1.1,2.2,3.1,3.9,2.4,6.8c-2.6,12-3.7,24.2-5.8,36.2
	c-0.7,3.9-0.6,4-4.5,3.4c-13.7-2-27.3-4.3-40.8-7.3c-8.3-1.9-16.6-3.6-24.9-5.3c-1.7-0.4-3.4-0.6-5.1-1c-1.5-0.3-2-1.1-1.6-2.7
	c2.3-8.4,4-16.9,6.3-25.2c0.8-2.8,2-5.5,2.9-8.2c0.6-1.8,0.3-3-1.4-3.6c-1.1-0.4-1.1-1-1.1-1.9c0.3-2.8,1.9-4.8,4.2-6.1
	c0.8-0.5,1.7-0.9,2-1.8c1.3-4,4.1-7,6.5-10.2c1.3-1.7,1.3-3.7,0-4.8c-4.1-3.5-3.2-8.1-2.4-12.1c2.7-14.1,5.9-28.2,9.5-42.2
	c0.4-1.6,0.9-2.1,2.5-1.7c2.1,0.6,4.2,1,6.3,1.4c1.6,0.3,2,1.2,1.6,2.7c-1,4.1-1.7,8.3-2.9,12.4c-0.5,1.7-0.4,3,0.4,4.5
	c1.3,2.2,2.3,4.3,1,7c-0.8,1.9,0.6,3.8,2.1,4.7c2.2,1.3,3.1,3.3,3.7,5.4c0.8,3.1,2.8,5.4,4.7,7.8c0.1,0.1,0.2,0.3,0.3,0.4
	c3.9,0.8,1.9,3,1.2,4.8c-1.3,3.4-2.6,6.9-4.1,10.2c-1.1,2.5,0.2,4.1,1.7,5.7c0.8,0.9,1.7,0.3,2.5-0.1c0.7-0.3,1.3-0.8,1.9-1.2
	c1.3-0.9,2.3-0.8,2.2,1c-0.3,3.3,1,6.2,1.6,9.3c0.4,2.1,1.4,3.9,3.1,5.2C187.7,170.5,188.3,171.5,188.5,172.6z" />
              <radialGradient id="SVGID_13_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st12" d="M402.4,328.9c-12.6,0-25.2-0.5-37.7-0.6c-12.2-0.1-24.4-1.3-36.7-1.5c-2.1,0-4.1-0.6-6.2-0.5
	c-1.5,0.1-2.1-0.8-2-2.2c0.2-3.6-0.4-7.2,0.7-10.7c0.4-1.2,0.3-2.6,0.2-4c-0.2-5.9-0.2-11.9,0.7-17.7c0.8-5.3,0.7-10.4,0.8-15.7
	c0-1.9,0.9-2.5,2.6-2.4c7.9,0.6,15.8,0.2,23.7,0.9c8.5,0.8,17,0.2,25.5,0.6c12.2,0.6,24.3,0.3,36.5,0.4c1.5,0,2.7,0.7,3.9,1.4
	c0.7,0.4,1.3,1,2.2,1.1c1.9,0.2,2,1,0.8,2.4c-1.6,1.9-1.4,3.9,0.2,5.7c1.3,1.5,2.2,3.4,4.1,4.3c0.8,0.4,0.6,1.5,0.7,2.3
	c0.7,8.1,0.5,16.2,0.5,24.2c0,2.9,0,5.8,0,8.8c0,1.6-0.6,2.3-2.3,2.5C414.6,329,408.5,328.9,402.4,328.9z" />
              <radialGradient id="SVGID_14_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st13" d="M397,230.1c-0.3-0.1-0.7-0.2-0.8-0.5c-1.5-4.4-6-4.4-9.3-6c-1.9-0.9-4.9-0.2-7.3,0.8c-1.3,0.5-2.4,0.5-3.5-0.4
	c-3.4-2.9-7.4-3.3-11.8-3.4c-11.5-0.3-22.9-1.5-34.4-1.7c-6.8-0.2-13.4-1.2-20.2-1.2c-2.4,0-4.8-0.4-7.2-0.5c-1.6-0.1-2-1-1.9-2.3
	c0.2-4.1,0.2-8.2,0.7-12.2c0.6-5,0.9-10.1,1.2-15.2c0.4-6.6,2.4-13.1,1.7-19.9c-0.2-1.8,1.3-2.1,2.7-2c5.3,0.3,10.6,0.7,15.9,1.1
	c4.3,0.3,8.6,0.7,12.9,0.9c14,0.8,27.9,1.6,41.9,1.7c5.2,0,10.5,0.1,15.7,0.3c0.9,0,2.1-0.4,2.6,0.9c0.5,1.2-0.6,1.8-1.3,2.4
	c-2.1,1.8-2.4,3,0,4.7c3.1,2.3,3.1,5.1,3.1,8.3c-0.1,7.8,0,15.5,0,23.3c0,1.2-0.1,2.3-1.2,3.3c-1.5,1.3-1.2,3.4-0.2,4.6
	c1.6,1.9,1.2,3.7,0.7,5.6c-0.6,2.4-2.3,4.8,0.7,7.1C397.6,229.9,397.3,230,397,230.1z" />
              <radialGradient id="SVGID_15_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st14" d="M395.2,274.8c-12.3,0.2-25.2-0.3-38.1-0.7c-10.7-0.3-21.5-0.7-32.2-1.3c-1.6-0.1-2.1-0.8-2.2-2.3
	c-0.1-3.6,0.5-7.1,0.8-10.7c0-0.7,0.2-1.3,0.2-2c0.1-3.3-0.1-3.5-3.5-3.5c-7.2-0.1-14.2-1.7-21.4-1.6c-1.5,0-1.7-1-1.6-2.3
	c1.1-9.2,1.9-18.5,2.4-27.8c0.2-4.4,0.6-4.7,4.9-4.4c12.3,0.8,24.6,1.7,36.8,2.4c3.7,0.2,7.3,0.6,11,0.5c5.7-0.1,11.3,0.8,17,0.6
	c1.6-0.1,2.6,0.8,3.8,1.7c2.5,1.8,4.7,3.1,8.2,1.7c4.6-1.8,10.8,0.8,13.8,4.8c0.1,0.2,0.3,0.5,0.4,0.6c3.3,0.7,2.7,3.9,3.7,6
	c1.8,3.8,2.1,8.2,4.5,11.8c0.1,0.2,0.3,0.5,0.3,0.7c-0.2,3.5,1.8,6.6,1.7,10c-0.1,5.1,2.2,9.2,4.7,13.3c0.4,0.7,1.4,1.3,1.1,2.1
	c-0.4,0.8-1.5,0.4-2.3,0.4C404.8,274.8,400.3,274.8,395.2,274.8z" />
              <radialGradient id="SVGID_16_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st15" d="M387.5,168.7c-9.2-0.7-18.5-0.2-27.7-0.6c-11.6-0.5-23.1-1.6-34.6-2c-5.9-0.2-11.8-1-17.7-1.4
	c-1.8-0.1-2.2-0.9-2.3-2.5c-0.4-5.2,1-10.2,1.3-15.4c0.4-6.7,1.4-13.4,1.7-20.1c0.2-4.5,1.2-8.9,1.1-13.4c0-1.5,1-1.8,2.2-1.6
	c9.8,1,19.8,1.2,29.6,2c7.4,0.6,14.8,0.6,22.2,1.1c7.1,0.4,14.2,0.2,21.2,0.4c4.3,0.1,6.3,2.2,5.7,6.5c-0.7,5.5-0.6,10.9,2.2,16
	c0.8,1.5,0.9,3.6,0.9,5.4c-0.1,3.8,0.6,7.6,0.6,11.5c0,1.4,0.2,2.7,0.6,3.9c0.5,1.5,1,3,1.5,4.5c1.4,4.8,0.6,5.8-4.2,5.8
	C390.4,168.7,388.9,168.7,387.5,168.7z" />
              <radialGradient id="SVGID_17_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st16"
                  d="M425.9,371.1c0,5.1-0.1,10.2,0,15.3c0,2.1-0.7,2.4-2.5,1.7c-2.2-0.8-4.3-1.8-5.9-3.6c-0.9-1-2-1.6-3.1-0.2
	c-0.7,0.8-2,1-2.5,0.5c-2-2.1-3.7,0.4-5.5,0.1c-1.9-0.3-4-0.2-5.3,1.9c-0.2,0.3-0.8,0.7-1,0.6c-2.9-1-6.2-1-8.7-3
	c-1.1-0.8-1.3-0.8-2,0.2c-2.2,3.3-3.5,3.4-6.3,0.8c-1.7-1.5-3.4-2.7-5.6-0.8c-0.4,0.3-1.3,0.5-1.3,0c-0.1-1.9-2.2-1.8-2.7-3.1
	c-0.9-2.1-3-2-4.2-1.7c-2.8,0.6-5.1-0.4-7.6-0.9c-0.8-0.2-1.5-0.7-2.3-0.8c-1.1-0.1-1.6-0.6-1.7-1.7c-0.1-1.9-1.4-2-3-2.1
	c-1.8-0.1-3.5,1-5.5-0.4c-3.6-2.3-3.2-5.6-2.8-8.9c0.6-4.7,0.3-9.5,0.8-14.2c0.3-3.9,0.8-7.8,0.7-11.7c-0.1-2-0.8-2.5-2.7-2.6
	c-11-0.4-21.9-1-32.9-1.5c-0.8,0-1.6-0.2-2.5-0.4c-1.3-0.3-3.1,0.6-3.7-0.8c-0.9-1.9-0.4-4.1-0.2-6.2c0.1-0.9,0.9-0.7,1.6-0.7
	c10.9,0.1,21.8,1,32.7,1.5c4.3,0.2,8.6,0.3,13,0.6c10.9,0.6,21.8,0.1,32.7,0.6c9.3,0.5,18.7,0.2,28,0.3c2.2,0,4.5-0.3,6.7-0.5
	c1.7-0.1,2.5,0.6,2.4,2.3c-0.2,7.7,2.2,15.1,2.8,22.7C426.2,360,425.9,365.6,425.9,371.1C425.8,371.1,425.9,371.1,425.9,371.1z" />
              <radialGradient id="SVGID_18_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st17" d="M423.9,317.1c0-8.3,0.2-16.7-0.5-25c-0.1-1.2-0.3-2-1.5-2.6c-2.1-1.1-3.1-3.2-4.3-5.1c-0.7-1.1,0-2.2,0.8-3.2
	c1.7-2.2,1.1-3.8-1.6-4.3c-0.6-0.1-1.4-0.3-1.8-0.7c-2.4-2.5-4.5-5.3-5.7-8.6c-0.6-1.8,0.2-2.4,1.9-2.4c5.3,0,10.7,0.1,16,0
	c10-0.1,20-0.4,29.9-1.5c2.8-0.3,5.3,0,6.9,2.8c0.3,0.6,0.7,1,0.6,1.8c-0.7,4.8,0.7,9,4,12.5c1.4,1.5,2.9,3,4.5,4.3
	c1.9,1.6,3.6,3.1,3.5,5.9c-0.1,1,0.8,1.6,1.9,1.7c0.6,0,1.2,0,1.7,0c3.3,0.1,4.6,2,3.5,5.1c-0.3,0.9-0.6,1.7-1,2.5
	c-1.8,4.1-1.9,4.2,1.3,7.1c2.2,2,4,4.5,7.3,4.8c1.1,0.1,1.4,1.1,1.9,1.8c1.9,2.7,2,5.9,2.7,8.9c0.5,2,1.3,3.4,3.6,3.4
	c0.7,0,1.5,0.4,1.3,1.2c-0.3,1.4,0.2,3.1-1.2,4.2c-2.2,1.7-4.2,3.3-4.3,6.7c-0.1,4.3-3,6.3-7.4,6c-1.5-0.1-2-0.6-1.2-2
	c0.4-0.6,0.8-1.3,1.3-1.8c1.1-1,1.1-2.2,0.6-3.4c-0.4-1.2-1.4-1.8-2.7-1.8c-6-0.1-12,0.4-17.9,0.9c-3.8,0.3-7.7,0.3-11.5,0.5
	c-9.8,0.5-19.7,0.4-29.5,0.7c-2.6,0.1-3.3-0.8-3.3-3.3C424,328.5,423.9,322.8,423.9,317.1z" />
              <radialGradient id="SVGID_19_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st18" d="M91.9,71.4c1,0.3,2.3,0.5,3.4,0.9c16.2,4.8,32.7,8.7,49,13c3,0.8,6,1.4,9,2c2,0.4,2.8,1.2,2.2,3.4
	c-3.4,13.4-6.4,26.9-9.3,40.4c-0.4,1.8-0.4,3.6-0.4,5.5c0,2-0.7,2.7-2.8,2.2c-4.5-1.2-9.1-2.1-13.6-3.3c-5.6-1.6-11.2-2.1-17-1.9
	c-2.6,0.1-5.4,0.7-7.8,0.3c-3.3-0.5-6.9-0.1-10-1.4c-2.7-1.1-5.2-2.4-8.1-2.9c-0.9-0.2-1.5-0.1-2.3,0.3c-2.7,1.5-6.9,0.6-8.8-1.9
	c-0.6-0.7-0.3-1.4-0.3-2.1c0.1-2,0.5-4.1-0.3-5.9c-0.8-1.6-2.1-2.6-3.8-3.6c-2.5-1.5-4.7-3-7.7-3.6c-1.4-0.3-1.1-2.2,0.2-3.1
	c2.7-1.8,2.8-2.3,1-5c-0.8-1.3-0.3-2.3,1.2-2.5c1.8-0.2,1.5-0.8,0.8-2.1c-1.6-2.6-1.9-5.6-1.7-8.6c0.3-2.9,0.6-5.6-0.8-8.5
	c-1.2-2.3,0.1-4.9,1-7.2c0.3-0.9,1.3-0.5,1.8,0.2c4.2,5.5,10.5,7.1,16.5,9.3c1.6,0.6,3.6,1,4-1.7c0.1-0.5,0.6-0.9,1-1.3
	c0.3-0.3,0.6-0.2,0.8,0.2c0.2,0.3,0.2,0.6-0.2,0.8c-2.2,0.9-1.1,2.2-0.5,3.4c0.6,1,1.2,2,1.9,2.9c0.3,0.4,0.7,0.8,1.3,0.4
	c0.4-0.3,0.4-0.8,0.2-1.1c-1.2-3.2-0.6-6.2,0.4-9.4c0.8-2.4-1.1-4.2-1.9-6.3C90.1,71.5,90.3,71.2,91.9,71.4z" />
              <radialGradient id="SVGID_20_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st19" d="M589.6,353.4c-0.9,1.3-1.4,2.1-1.9,2.8c-0.7,1-0.7,2.2,0.2,2.7c1.7,1.2,3.2,3,5.6,3c1.6,0,2.5,0.8,3.1,2.1
	c1.6,3.6,4.4,5.9,7.5,8.3c3,2.3,6.8,4.2,8.4,8.2c0.4,1,1.3,1.9,2.3,2.2c3.7,1,4.9,4,6.1,7c0.5,1.4,0.9,2.5,2.5,3
	c1.9,0.6,2.3,2.6,2.7,4.2c0.4,2.1,1.3,3.4,3.6,3.4c1.6,0,1.7,0.9,0.9,1.9c-4,5.2-3.3,11.8-5.1,17.7c-0.1,0.3,0,0.7,0,1
	c0.2,3.8,0.2,3.8-3.8,3c-0.6-0.1-1.1-0.2-1.7-0.3c-2.9-0.5-4.2,1-3.2,3.7c0.4,1,0.6,2.1,0.5,3.1c0,0.4-0.1,0.9-0.6,1
	s-0.8-0.3-0.8-0.8c-0.1-2.7-2.1-2.5-3.8-2.3c-9.3,1.2-18.7,1.5-28.1,2c-2,0.1-3.9,0.5-6,0.5c-3.4-0.1-4.7-1-6-4.1
	c-2.3-5.5-2.6-11.4-2.7-17.3c0-0.5-0.1-1.1,0.1-1.5c2.5-3.8,0-6.6-1.7-9.6c-0.9-1.5-1.8-3-2.3-4.7c-2.1-7.7-4.1-15.4-6.1-23.2
	c-0.8-3.2-2.1-6.3-3.2-9.4c-0.9-2.6-0.4-3.5,2.4-3.9C568.5,355.7,578.8,355.4,589.6,353.4z" />
              <radialGradient id="SVGID_21_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st20" d="M614.7,480.9c-2-3.4-2.8-6.8-2.1-10.3c0.6-3,0.6-6,0.5-9c-0.1-2.3-3.8-6.5-6.1-7.1c-3.6-0.9-5.5-3.8-7.9-6.2
	c-2-2-3.8-4.3-6.5-5.5c-2.6-1.2-5.2-2.7-8-1.1c-1.8,1-3.1,2.9-4.6,4.4c-2.7,2.6-5.8,3.4-9.2,3.9c-0.8,0.1-1.1-0.1-1.2-0.8
	c-0.3-1.7-1.6-2.4-3-3c-1-0.5-2-1.2-3-1.8c-7.5-4.6-15.4-5.3-23.7-2.2c-3.4,1.3-3.9,1-3.8-2.7c0.1-2-0.3-3.6-2.2-4.6
	c-0.9-0.4-1.4-1.2-1.1-2.3c0.3-1.2,1.4-1,2.2-1.1c9.7-0.6,19.3-2.3,29-3.3c1.4-0.1,2.8-0.2,4.2-0.3c2.1-0.2,3.8,0.3,4.7,2.4
	c0.6,1.3,1.7,1.7,3.1,1.7c7.4-0.3,14.8-0.6,22.2-1.3c4.8-0.4,9.7-0.1,14.4-1.1c1.2-0.3,2.1,0.3,2.2,1.7c0.1,1.2,0.9,1.7,2.1,1.6
	c1.3-0.1,1.4-1,1.4-2c0-1.3,0-2.6-0.6-3.9c-0.8-1.6,0.1-2.4,1.7-2.2c1.8,0.2,3.6,0.5,5.4,0.7c1.5,0.2,1.5,1.4,1.8,2.4
	c2.2,6.6,4.5,13,8,19.1c2.6,4.5,6.3,8.1,9.5,12.2c1.1,1.4,1.7,2.6,1.3,4.7c-0.4,2.6,1,5.1,2.6,7.1c1.5,2.1,2.9,4.3,4.1,6.5
	c1.3,2.4,2.5,4.9,4.1,7.1c3.1,4.2,3.2,9.3,3.5,14.3c0.1,2.3-0.2,4.7,0.4,7c0.2,0.8,0,1.6-0.6,2.3c-1.5,1.8-2.1,3.9-1.4,6.3
	c0.5,2-1.8,4.6-4.8,5c-1.7,0.2-3.2,0.6-4.7,1.4c-1.8,1-2.8-0.2-2.8-1.8c0-3.6-2.4-5.7-4.2-8.3c-0.9-1.3-2.5-1.8-4-1.6
	c-2.7,0.5-3.9-0.9-4.5-3.1c-0.7-2.7-1.9-5-4.5-6.6c-1.5-0.9-1.5-2.8-1.5-4.6c0-1,0-2.2-1.2-2.4c-1.1-0.2-1.1,1-1.3,1.8
	c-0.3,1.1-0.8,1.2-1.5,0.3c-2.6-3-4.3-6.8-7.2-9.5c-0.7-0.6-0.1-1.2,0.3-1.8c0.8-1.4,1.6-2.9,2.4-4.4c0.6-1,0.7-1.9-0.3-2.6
	c-1.2-0.8-2.5-1-3.8-0.3c-0.8,0.4-0.3,1,0.2,1.4C616.1,478.4,615.4,479.4,614.7,480.9z" />
              <radialGradient id="SVGID_22_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st21" d="M475.6,229c3.5-0.5,6.8-0.4,10.2-0.7c7.3-0.8,14.6-0.6,21.9-1.5c1.9-0.3,3.5-0.1,3.4,2.6
	c-0.1,1.3,0.7,2.5,1.4,3.7c1.9,3.1,3.4,6.3,3.6,10.2c0.3,6.1,0.9,12.1,1.6,18.1c0.6,4.6,0,9.3,0.8,13.9c0.5,3.2,0.7,6.4,0.1,9.7
	c-0.4,2.3,1.7,4.1,1.7,6.4c0,1.2,0,2.3-0.6,3.4c-2.4,4.4-5.4,8.5-5.2,13.9c0.1,1.3-0.1,2.7-1.1,3.7c-0.2,0.2-0.3,0.5-0.3,0.7
	c1.6,3.2-1.1,3.2-2.8,4.1c-1.6,0.9-2,2.3-1.2,4c0.3,0.5,0.6,1.1,0.1,1.6c-0.5,0.7-1.3,0.4-1.8,0.1c-3.3-2.1-5.7-1.2-7.8,1.9
	c-0.6,0.9-1.6,0.8-2.1,0c-0.8-1.2-1.7-2.7-1.3-4.1c0.5-1.4,0.1-2.4-0.6-3.5s-1.3-2.3-1.9-3.5s-1.5-2.1-3-2.4
	c-3.5-0.7-5.6-3.5-7.8-5.9c-0.7-0.8-0.8-1.7-0.2-2.8c1.3-2.1,1.8-4.4,2.1-6.9c0.3-2.4-0.4-3.5-2.7-3.7c-0.8-0.1-1.7-0.1-2.5,0
	c-1.6,0-2.6-0.7-2.5-2.4c0.1-1.7-0.9-2.6-2-3.7c-4-3.8-8.9-6.9-9.8-13.1c-0.3-1.9-0.6-3.8,0.8-5.5c0.5-0.6,0.4-1.4,0.5-2.1
	c0.1-0.6,0.2-1.5,0.5-1.6c3.2-0.6,2.5-3.9,4-5.8c1.2-1.4,1.1-3.7-0.5-5.5c-1.3-1.4-0.8-3.2,1-3.9c0.3-0.1,0.6-0.2,1-0.2
	c5,0,6.7-3.6,8.1-7.5c1-2.7,0.8-5.1-1.9-6.8C477.3,232.7,477.5,230.2,475.6,229z" />
              <radialGradient id="SVGID_23_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st22" d="M411.1,213.2c11.9-0.1,23.8-0.5,35.7-0.6c5.2-0.1,10.5-0.2,15.7-0.8c3-0.3,5.1,2.8,4.2,5.7
	c-1.2,3.8,0.9,7.8,4.6,8.9c0.7,0.2,1.4,0.4,1.7,1c1.4,3.3,3.9,5.8,6.4,8.2c0.2,0.2,0.4,0.5,0.5,0.8c1.6,3.2-2.2,10-5.7,10.4
	c-1,0.1-2,0.2-2.9,0.5c-2.4,0.8-3.2,3.5-1.6,5.4s0.9,3.4,0.2,5.4c-0.9,2.8-4.2,3.9-4.3,7.1c0,1.3-1.2,0.7-1.6,0.1
	c-1.9-3.5-5.1-2.5-8-2.4c-4.4,0.2-8.8,0.6-13.2,0.9c-10.8,0.7-21.7,0.5-32.5,0.5c-3.4,0-3.6-0.3-3.8-3.7c-0.3-5.2-1.6-10.1-3.3-15.1
	c-1.5-4.6-4.1-9-4.2-14c0-0.3,0-0.8-0.2-1c-2.9-3.1-1.1-6.5-0.5-9.8c0.2-1.3,0.4-2.4-0.9-3.4c-1-0.8-1.1-2.1-0.7-3.2
	c0.4-1.3,1.6-1,2.6-1C403.4,213.2,407.3,213.2,411.1,213.2z" />
              <radialGradient id="SVGID_24_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st23" d="M516.9,176.1c-0.2,2.9-1.4,5.3-2.5,7.7c-1.6,3.6-3.3,7.1-2.5,11.2c0,0.1,0,0.2,0,0.2
	c-3.5,4.1-0.5,9.4-2.5,13.9c-1.7,4,0.2,7.8,0.9,11.7c0.2,0.9,0.5,1.8,0.6,2.7c0.2,1.4-0.4,2.1-1.9,2.2c-7.8,0.5-15.6,1-23.4,1.5
	c-1.3,0.1-2.7,0.2-4,0.5c-4.6,1.1-8.3-1.4-12.1-3.2c-1.9-0.9-2.7-4.5-1.8-7c0.6-1.5,0.5-2.6-0.6-3.9c-1.6-1.8-1.3-4.3-1.6-6.4
	c-0.3-2.3-1.4-3.9-3.2-5c-2.8-1.9-5.5-3.8-7.8-6.5c-1.3-1.5-3.3-3.1-5.7-3.6c-2.1-0.4-3.5-1.9-3.5-4.2c0-3-1.2-6.1,0.8-9
	c0.6-0.9,0.2-2.5-0.8-3.2c-2.8-1.7-1-3.5-0.3-5.3c0.2-0.5,0.7-1.1,1.2-1.3c4.1-1.6,4.5-4.9,4.1-8.6c-0.1-1.1-0.1-2.2,0.1-3.2
	c0.2-1.1,0.6-2.4,2.1-2.3c4.8,0.4,8.6-2.3,12.6-4.2c0.9-0.4,1.6-1.5,2.6-0.5c0.9,0.9-0.1,1.6-0.5,2.4c-0.3,0.6-0.6,1.2-0.8,1.8
	c-0.4,1.2-0.1,1.8,1.3,1.4c3.2-0.8,5.4,0.9,7.4,3c1,1,1.9,1.7,3.3,1.9c6.2,0.8,12,3.2,18.1,4.5c1,0.2,2,0.3,3,0.4
	c1,0.1,2.1,0,2.2,1.4c0.1,0.8,0.7,1.2,1.4,1.3c2.3,0.4,2.9,1.8,3.1,4.1c0.3,2.4,0.2,5,2.2,7c0.7,0.7,0.4,1.5-0.2,2
	c-1.7,1.6-1.9,3.8-2.9,5.8c-0.4,0.8-0.6,1.9,0.3,2.5c1.1,0.8,1.4-0.5,1.8-1.1c2.6-3.5,5.1-7.1,7.6-10.7
	C515.5,177.3,515.7,176.5,516.9,176.1z" />
              <radialGradient id="SVGID_25_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st24" d="M426.9,371.5c0-1.3,0-2.7,0-4c0.3-8.8-0.3-17.6-2.2-26.3c-0.5-2.2,0.2-2.9,2.3-2.9c9.6,0.4,19.2-0.5,28.7-0.6
	c9.2-0.1,18.4-0.9,27.7-1.4c0.7,0,1.3,0,2,0c0.9,0,1.9,0.1,2.3,1.1s0.2,1.9-0.6,2.7c-1.2,1.2-2.8,3-2.1,4.5c0.7,1.5,3,0.7,4.6,0.6
	c3.3-0.1,3.3-0.3,2.4,3c-0.6,2.2-0.6,4.6-3.3,5.7c-0.9,0.4-0.5,1.3-0.2,2c0.8,2.2,1.9,4.5-1.4,5.7c-0.6,3.8-5.4,5.8-4.2,10.3
	c0.1,0.5-0.1,1-0.6,1.3c-2.6,1.5-4.4,3.6-4.2,6.9c0,0.2-0.3,0.7-0.6,0.7c-2.4,0.7-2.3,2.8-2.4,4.5c-0.2,3.2-0.5,6.4,1,9.6
	c1.2,2.5,0.3,3.3-2.4,3.6c-12,1.2-24,1-36,1c-4.3,0-4.3,0-4.3-4.4c0-0.8,0-1.7,0-2.5c-0.1-2.4-1.3-3.3-3.6-2.6
	c-2.2,0.6-2.9,0.2-2.9-2.2C426.9,382.3,426.9,376.9,426.9,371.5z" />
              <radialGradient id="SVGID_26_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st25" d="M529.8,444.8c1.2-0.9,1.1-1.9,0.4-3.1c-0.5-0.9-0.9-1.8-1.4-2.7c-0.5-0.9-0.9-2-2.1-1.8
	c-1.1,0.2-1.1,1.3-1.4,2.1c-0.2,0.5-0.3,1.1-0.5,1.7c-0.4,1.1-1,1.7-2.2,1.5s-1.4-1-1.5-2c-0.7-6.6-1.1-13.2-2.2-19.8
	c-1.1-7.1-1-14.1-0.8-21.2c0.4-11.6,0.8-23.1,0.9-34.7c0-0.3,0-0.7,0-1c-0.1-3.1,0.1-3.2,3.1-3.5c9.9-0.9,19.9-1.1,29.8-2.4
	c1.5-0.2,2.4,0.1,2.8,1.9c0.4,2.3,1.3,4.6,2.1,6.9c1.9,5.2,2.6,10.7,4.4,15.9c2,5.9,2.6,12.2,6.1,17.6c1.3,2,2.8,4.5,1.2,7.7
	c-1.1,2.1-1.4,5.1,0.1,7.7c0.7,1.2,0.7,2.6,0.5,3.9c-0.2,2-0.9,4,1,5.8c0.8,0.8,0,1.6-1,1.6c-5.2,0-10.2,1.2-15.4,1.5
	c-5.1,0.2-10,1.6-15.1,1.8c-1.3,0-2.7,0.2-4,0.3c-1.1,0.1-2.5-0.3-2.9,1.2s-0.1,2.9,1.2,3.8c1.2,0.8,2.1,1.7,2.1,3.4
	C535.2,443,535.3,443,529.8,444.8z" />
              <radialGradient id="SVGID_27_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st26" d="M690.9,315.5c-2.7-3.1-4.7-1-6.8,0.3c-1.4,0.8-2.9,1.7-4.3,2.6c-0.6,0.3-1,0.7-0.6,1.4
	c0.3,0.5,0.8,0.5,1.3,0.4c1.1-0.2,2.6-0.2,3.3-0.9c2-1.7,4.4-0.5,6.5-1.4c1.4-0.6,1.9,1.6,2.5,2.6c0.7,1.2,0.8,2.5-0.4,3.4
	s-1.9,2-2.4,3.3c-0.9,2.3-2.9,2.7-5.2,2.4c-0.7-0.1-1.6-0.5-2,0.3s0.7,0.7,0.9,1.2c0.6,1.1,0.2,2.3-0.2,3.3
	c-0.6,1.5-0.5,2.3,1.4,2.5c1.2,0.1,2.9,0.3,2.8,2c0,1.9-1.8,3.2-3.7,3.1c-4-0.1-6.8,2.5-9.5,4.9c-3.2,2.8-5.8,6-6.3,10.5
	c-0.2,1.5-1,2.6-2.8,2.5c-0.5,0-1.1,0-1.5,0.3c-3.2,2.1-5.6,0.8-8.3-1.3c-4-3.1-8.3-5.9-12.4-8.9c-2-1.5-4.1-1.8-6.6-1.4
	c-3.4,0.5-6.8,0.5-10.2,1.2c-0.7,0.1-1.3,0-1.7-0.7c-2.1-3.9-5.6-3.9-9.3-3.6c-3.5,0.3-7,0.7-10.4,0.9c-2.2,0.1-4.3,0.6-6.2,1.8
	c-7.2,4.7-15.4,5.4-23.6,6.1c-0.8,0.1-1.3-0.2-1.4-1c-0.2-1-0.1-2.2,1-2.5c2-0.5,2.6-1.6,3.2-3.5c0.4-1.6,2.3-2.6,4-3.1
	c2.2-0.6,3.9-1.7,5.4-3.4c0.5-0.5,1.2-0.9,1.9-1.2c1.4-0.6,2.1-1.4,2.6-3c0.5-1.7,1.6-3.7,4.2-2.6c0.2,0.1,0.7,0.2,0.7,0.1
	c1.3-3.3,6-1.9,7.2-5.3c0.4-1.1,0.9-2.2,2.2-2.6c1.1-0.4,1-1.4,1-2.3c0-1.4,0.6-2,2.1-2.2c15.1-2,30.2-4.1,45.1-7.1
	c5.9-1.2,11.9-2.3,17.8-3.4c4.5-0.9,9-1.6,13.4-3c1.7-0.6,2.6-0.2,3.3,1.4c0.7,1.7,1.6,3.3,2.3,4.9C691.1,315,691,315.2,690.9,315.5
	z" />
              <radialGradient id="SVGID_28_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st27" d="M695.3,231.6c0.6-1.2,1.2-2,1.4-2.8c1.5-5,0.7-6.3-4.4-7.4c-2.8-0.6-5.3-2.2-7.9-2.9c-3.4-1-4.7-3.2-6.2-5.8
	c-1.1-2-4.4-3-6.7-2.5c-8.1,1.8-16.1,3.8-24.2,5.2c-9.1,1.6-18,4-27.2,4.9c-0.9,0.1-2.2,1-2.7-0.3c-0.6-1.4-0.6-2.9,0.5-4.2
	c0.9-1,1.5-2.4,2.6-3c2.7-1.5,3-4.7,5.4-6.4c0.6-0.4,0.5-1.1,0.3-1.8c-0.4-1-1.1-1.6-1.9-2.3c-2.6-2.2-2.4-4.8,0.8-6
	c5-1.9,9.9-2.9,15.5-2c3.3,0.5,7.4-0.9,10.4-3c2-1.4,3.1-4,5.7-5.1c1.1-0.5,0.8-1.7,0.5-2.7c-0.5-1.3-1-2.6-0.3-4.1
	c0.6-1.1-0.4-1.5-1.3-1.9c-2.3-1-2.6-2.4-0.8-4.2c2.5-2.5,4.8-5.1,6.3-8.3c1.3-2.7,3.5-4.6,5.4-6.9c1.5-1.8,3.8-2.1,5.9-2.4
	c4.1-0.6,8-1.4,11.9-2.7c1.5-0.5,2.3-0.2,2.5,1.6c0.3,3,2,5.6,2.7,8.5c0.2,0.7,0.4,1.6,0.2,2.2c-0.7,2.2-0.1,4.1,0.9,6
	c0.6,1.3,1.3,2.7,0.9,4.3c-0.2,0.8,0.2,1.6,1,1.8c1.9,0.4,2.1,1.9,2.5,3.3c1.4,4.8,2.3,9.8,1.8,14.8c-0.6,6.1,1.5,11.9,2.2,17.8
	c0.3,2.4,0.5,4.7-0.3,7.1c-0.6,1.7,0.7,3.5,0.3,5.3c-0.2,0.8,0.8,0.6,1.2,0.2c0.7-0.7,1.4-1.4,2.2-2.1c0.6-0.6,1.2-0.9,2.2-1
	c4.2-0.1,7.7-2.1,10.9-4.7c0.4-0.4,0.6-1.1,1.3-1.1c0.9-0.1,1.8,0.3,2.3,0.9c0.7,0.8-0.3,1.3-0.8,1.7c-6.1,4.8-12,10-20.2,10.7
	C697,230.3,696.5,231.1,695.3,231.6z" />
              <radialGradient id="SVGID_29_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st28"
                  d="M518,369.9c-0.6,8.7-0.2,17.5-0.6,26.2c-0.2,4.9-0.4,9.8-0.3,14.7c0.1,7.2,1.2,14.4,2.3,21.5
	c0.4,2.8,0.6,5.6,0.7,8.5c0.1,3.1-0.9,4.2-3.7,3c-3.6-1.6-6.6,1-10,0.6c-0.9-0.1-1.3,1.2-1.8,1.9c-0.9,1.1-1.7,0.8-1.9-0.4
	c-0.2-1.8-1.2-3.1-2.4-4.2c-1.7-1.6-1.2-3.4-0.8-5.3c0.7-2.9,0.5-3-2.5-2.9c-4.3,0.2-8.6,0.3-13,0.5c-3.1,0.2-6.1,0.4-9.2,0.5
	c-3.3,0.1-3.5-0.4-3.1-3.6c0.6-4.8,2.6-8.9,5.3-12.8c0.8-1.1,1.4-2.2,1.9-3.5c1.4-3.3-0.6-5.9-1.6-8.7c-0.8-2.4-2.2-4.6-0.2-7.3
	c0.9-1.2,0.4-3-0.1-4.6c-1.1-3.3-1.1-6.7-0.6-10c0-0.3,0.1-0.9,0.2-0.9c3.1-1.3,1.8-5.2,3.7-6.7c2.9-2.2,3.5-5,4.1-8.1
	c0.4-2.1,3.2-2.6,3.4-5c0-0.5,1-0.5,1.6-0.5c8.7-0.3,17.4-1,26.1-1.9c1.8-0.2,2.3,1.2,2.4,2.6C518.1,365.7,518,367.8,518,369.9z" />
              <radialGradient id="SVGID_30_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st29" d="M465.4,461.3c-4.3,0.5-8.4,0.1-12-1.8c-4.4-2.2-8.8-2-13.4-1.1c-2,0.4-2.7-0.6-1.9-2.1c1.9-3.5,0.9-7.4,1.5-11
	c0.1-0.8,0.1-1.6,0.5-2.4c3-5.7,2.2-11-1.6-16c-0.3-0.3-0.6-0.7-0.7-1c-1.6-5.6-5-10.7-4.1-16.9c0.3-2,0.2-4-0.1-6
	c-0.4-2,0.3-2.6,2.1-2.5c3.9,0,7.8,0,11.8,0c8.5,0.1,17-0.1,25.5-0.9c1.1-0.1,1.7,0.2,1.9,1.4c0.5,2.2,1.2,4.3,1.6,6.5
	c0.2,0.9,0.3,1.8,1.1,2.4c0.6,0.4,0.8,1.1,0.6,1.8c-0.9,3.7-2.5,7-4.7,10.1c-2.3,3.4-2.9,7.4-3.2,11.4c-0.1,1.7,0.7,2.4,2.4,2.3
	c5.1-0.3,10.1-0.6,15.2-0.9c3.2-0.1,6.3-0.1,9.5-0.2c1.5-0.1,1.2,0.9,0.9,1.6c-1,2.9-0.1,5.1,1.8,7.3c2.2,2.4,1.9,5.2-0.5,7
	c-0.6,0.5-1.4,0.9-1,1.8c0.5,1.2,1.7,1.5,2.9,1.7c0.8,0.2,1.4-0.2,1.7-1.1c0.2-0.7,0.1-2,1.2-1.9c1.1,0.2,1.7,1.3,2,2.4
	c0.2,1-0.5,2-1.4,2c-2.6,0.2-3.3,2.1-3.1,3.9c0.2,1.9,2.4,2.4,4.1,2.7c2,0.4,3.9,0.7,5.4,2.3c0.5,0.6,1.5,1,0.9,2
	c-0.5,0.9-1.2,1.2-2.3,1c-1.3-0.2-2.4-0.4-3.3-1.5c-0.8-1.1-2.2-1.5-3.5-1.8c-1.4-0.4-2.7-0.6-3.2-2.3c-0.2-0.7-0.8-0.8-1.4-0.4
	c-0.7,0.5-2,0.2-2,1.5c0,0.6,0,1.1-0.2,1.7c-1,3.2-0.9,3.2-3.2,0.9c-0.5-0.5-1.1-0.9-1.5-0.4c-1.2,1.3-3.4,1-4.2,2.9
	c-0.4,1-1.6,1.4-2.6,1.1c-2.4-0.5-4.6-1.6-6.6-2.8c-0.8-0.5-0.4-1.1,0-1.7c1.1-1.7,0.2-2.5-1.4-2.8c-1.9-0.3-3.6-0.8-4-3
	c0-0.2-0.3-0.4-0.5-0.6c-0.8-0.7-6.8-1.2-7.7-0.6C462.4,459.1,465,459.8,465.4,461.3z" />
              <radialGradient id="SVGID_31_" cx="396.2129" cy="323.7975" r="357.3461" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st30" d="M615.9,262.4c-1.1,0.3-1.4-0.4-1.8-1.9c-1.4-5.1-1.7-10.5-2.7-15.7c-1.3-6.6-2.1-13.3-3.1-20
	c-0.1-0.8,0-1.5,0.6-2.1c1.9-1.7,4.1-3.1,6-4.9c0.9-0.9,1.4,0.1,1.3,0.9c-0.2,2.9,1.2,2.9,3.6,2.6c17.5-2.5,34.6-6.6,51.9-10.2
	c2.3-0.5,5.2,0.9,6.6,3.5c1.1,2,1.9,4.2,4.8,4.1c0.5,0,1,0.6,0.6,1c-1.2,1.5-0.6,3.6-1.9,5.1c-0.8,0.9-1.2,2.2-0.5,3.5
	c0.4,0.8,0.2,1.3-0.3,2c-1.2,1.8-0.4,3.6,1.4,4.9c2,1.5,3.5,3.4,5.6,4.9c0.7,0.5,0.4,1.3-0.2,1.6c-1.4,0.8-2,2.1-2.8,3.4
	c-1,1.6-2.2,3-4.3,2.9c-1.1,0-1.9,0.7-2.5,1.4c-0.9,1.1-2.1,1.7-3.5,1.8c-8.4,1-16.5,3.7-24.9,4.9c-10.4,1.6-20.7,3.7-31.1,5.6
	C617.9,262.2,617.2,262.3,615.9,262.4z" />
              <radialGradient id="SVGID_32_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st31" d="M535.4,175.8c-0.3,1.6-0.6,3.2-0.8,4.8c-0.1,0.6-0.1,1.2,0.5,1.6c0.8,0.4,1.2-0.2,1.7-0.6
	c1.7-1.4,1.4-3.4,1.1-5.1c-0.5-2.6,0.2-4,2.8-4.4c0.7-0.1,1.6-0.3,1.8-1.1c0.2-0.9-0.9-0.7-1.4-1.1c-0.8-0.9-1-1.9-0.4-3
	c1-1.8,3.9-1.9,5.8-1.7c4.7,0.5,8.3,4,13,4.6c2.2,0.3,2.5,2.4,3.6,3.7c0.1,0.1,0,0.6-0.1,0.6c-2.8,1.4-0.8,2.5,0.2,3.7
	c1.9,2.1,1.8,8.3-0.2,10.6c-0.3,0.3-0.6,0.7-0.6,1.1c-0.3,2.2-0.9,3.9-3.2,5.1c-1.4,0.7-0.8,4.4,0.7,5.6c2,1.7,3.1,0.3,4.3-1.2
	c0.9-1.1,1.3-2.5,2-3.7c1.1-2.1,2.8-3.7,5-3.6c2.4,0.1,3.5,2.4,4.1,4.5c1,3.4,1.4,6.9,2.9,10.1c1.3,2.7,1.4,5.4,1.4,8.2
	c0,0.8,0.2,1.8-0.7,2.3c-1.3,0.7-0.8-0.8-1.3-1.1c-0.8-0.6-1.2,0.2-1.2,0.7c-0.3,3.9-4.1,6.2-4,10.4c0,2.1-1.9,4.3-3.3,5.9
	c-1.1,1.3-3.8,1.3-5.9,1.5c-4.7,0.5-9.3,1.5-14.1,1.3c-5.2-0.3-10.4,0.5-15.6,1.6c-1.9,0.4-4.2,1-5.5-0.2c-0.7-0.6,0.6-3,1.2-4.5
	c2.3-5.6,3.8-11.2,1.8-17.2c-1-3.1-3.2-5.6-4.3-8.7c-0.5-1.4-1-2.6-0.1-4c0.3-0.4,0.4-1,0.2-1.4c-1-3.4,0.1-6.6,1.1-9.8
	c0.5-1.5,0.7-2.9,0.1-4.4c-0.1-0.4-0.3-1.1-0.1-1.2c1.9-1,1.2-3.4,2.3-4.5c1.7-1.7,3.6-3.2,4.6-5.6
	C535.1,175.6,535.2,175.7,535.4,175.8z" />
              <radialGradient id="SVGID_33_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st32" d="M599,280.2c-3.7-1.9-4.6-1.6-5.1,1.7c-0.3,2.4-0.7,4.7-1.8,6.9c-0.5,1.1-1.2,1.8-2.2,1.3
	c-1.7-0.9-3.7-1.3-4.6-3.5c-0.7-1.8-2.4-1.6-3.5-0.6c-1.3,1.3-2.7,1.5-4.3,1.3c-1.2-0.1-2.5-0.2-3.6,0.6c-0.2,0.2-0.8,0.2-0.9,0.1
	c-1.5-2-3.6-1.6-5.7-1.8c-1-0.1-1.3-1-1.6-1.7c-1-2.4-2.8-3-5.1-2.4c-1.9,0.4-2.8,0.1-3.1-2.2c-1.2-9.4-2-18.9-3-28.3
	c-0.5-4.1-1-8.2-1.6-12.3c-0.3-1.9,0.3-2.6,2-2.9c4.3-0.7,8.5-1.5,12.8-2.1c1.7-0.3,3.4-0.3,5.1,0.7c1.1,0.7,2.5,0.8,3.9,0.7
	c1.5-0.1,2.9,0.3,3.9,1.3c1.5,1.4,2.9,1.2,4.5,0.3c2-1,4.1-1.9,6.3-2.2c2.6-0.4,4-2.7,5.5-4.5c2.4-2.9,5.7-4.2,8.9-5.9
	c1.4-0.7,1.5,0.6,1.7,1.4c0.9,4.9,1.9,9.8,2.3,14.8c0,0.5-0.1,1.1,0.1,1.5c2.6,5.9-0.4,11.6-0.3,17.4c0,3.5-1.1,6.4-3.4,9.1
	c-0.8,1-1.5,2.1-3,1.6c-1-0.3-1.5,0.3-2.1,1C599.3,274,597,276.2,599,280.2z" />
              <radialGradient id="SVGID_34_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st33" d="M489.6,361.7c1.3-1.6,0.7-3.1,0.4-4.8c-0.2-0.8-0.8-2,0.1-2.6c3.7-2.3,2.6-6.7,4.3-9.9c1.3-2.4,2.3-5,2.2-7.9
	c0-1.1,1-1.2,1.9-1.2c5.5,0.4,10.9-1,16.4-1c1.5,0,2.6-0.3,2.6-2.1c0-0.8,0.4-1.2,1.3-1.1c5.2,0.3,10.2-1,15.4-1.3
	c3.1-0.2,6.3-0.9,9.4-0.8c5.5,0,11-0.3,16.4-1.4c3.7-0.7,7.5,0.1,11.2-0.6c7.6-1.3,15.3-1.9,22.9-3.3c2.4-0.4,4.8-0.5,7.2-0.8
	c0.8-0.1,1.7,0,2.4-0.5c0.6-0.4,1.4-1.4,2.1-0.3c0.6,0.9,0.6,2.1-0.2,2.8c-1.3,1.2-2.3,2.6-2.9,4.3c-0.3,0.7-0.9,0.9-1.6,0.8
	c-1.2-0.2-2,0.7-2.9,1.2c-0.9,0.6-1.5,2.9-3.1,0.6c-0.2-0.3-0.9,0.2-1.2,0.6c-1.2,1.5-3.4,2.3-3.4,4.7c0,0.2-0.2,0.6-0.4,0.6
	c-3.1,1-4.7,4.2-8,5.3c-2.4,0.8-4.6,1.9-5.9,5c-0.9,2.1-4.1,3.4-4.2,6.5c0,0.2-1,0.3-1.6,0.4c-6.5,0.3-12.9,1.8-19.3,2.1
	c-5.4,0.3-10.7,1.3-16.1,1.5c-6.9,0.3-13.8,1.1-20.6,1.7c-5.1,0.4-10.1,1-15.2,1.1C496.1,361.5,493,362.5,489.6,361.7z" />
              <radialGradient id="SVGID_35_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st34" d="M545.3,327.9c-5.7-0.1-12.2,1.1-18.8,1.8c-2.5,0.3-4.9,0.7-7.4,0.3c-1.4-0.2-2.5,0-2.6,1.8
	c0,1-0.6,1.5-1.6,1.6c-4.6,0.3-9.3,0.7-13.9,1c-0.6,0-1.5,0.3-1.8-0.4c-0.4-0.9,0.8-0.8,1.2-1.2c1.8-1.9,1.6-4.3,1.3-6.5
	c-0.3-2.4,0.8-3.8,3.2-3.3c1.3,0.3,2.5,1,3.7,1.4c2,0.7,2.6,0.1,2.3-2c0-0.3-0.1-0.7-0.3-1c-1.2-2.2-0.3-3.4,1.9-4
	c1.8-0.5,3.8-0.9,2-3.6c-0.6-0.9,1.8-4.4,3-4.7c1.6-0.4,3.2-0.8,4.9-1.1c1.2-0.2,2.3,0.2,3.2,0.9c1.3,1,2.4,1.2,3.4-0.6
	c1-1.7,2.2-3.4,4.2-0.6c0.9,1.2,2.4,0.5,2.6-1.1c0.2-2.5,1.3-3.2,3.6-2.4c1,0.3,2.1,0.2,3.2,0.2c0.9,0,1.7-0.4,1.7-1.4
	c0-2.5,1.7-4.1,3.1-5.8c1.3-1.5,3.4-2.5,3.1-5.1c-0.1-0.7,0.7-1.1,1.4-1.2c1.7-0.2,3.1-1.2,4.5-2.1c1.5-0.9,2.3-1.9,1.3-3.7
	c-0.3-0.5-0.4-1.1,0.2-1.6c1.9-1.5,5.8-0.7,6.7,1.6c0.6,1.6,1.5,2.3,3.2,2.2c1.5-0.1,3,0,4,1.4c0.5,0.7,1.5,0.6,2.3,0.2
	c0.5-0.3,1.1-0.8,1.6-0.7c2.7,0.8,5,0.8,7-1.6c0.4-0.5,1.1-0.1,1.5,0.5c0.2,0.3,0.2,0.8,0.4,0.9c2.9,1.8,4.6,4.1,4.1,7.6
	c3.2,2.5,4.1,6.8,7.7,8.9c1.9,1.1,2,2.3,0.3,3.9c-2.7,2.4-5.5,4.6-7.6,7.5c-1.3,1.9-2.2,4-4.4,5.4c-5.2,3.3-10.8,5.1-16.8,4.9
	C560.7,326,553.6,328.3,545.3,327.9z" />
              <radialGradient id="SVGID_36_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st35" d="M582.4,324c0.3-0.2,0.6-0.5,1-0.7c3.5-1.6,5.7-4.6,7.6-7.8c1.9-3.2,5.6-4.7,7.7-7.8c0.6-1,1.3-0.2,1.7,0.5
	c1.3,1.8,3.4,3.4,5.3,2.5c2.6-1.2,5.3-1.3,8-2.1c0.4-0.1,0.9-0.3,1.1-0.6c1.4-2.8,4.3-3.2,6.8-4.2c1.7-0.7,2.6-2,2.4-3.7
	c-0.1-1.8,0.4-3.3,1.2-4.8c2-3.8,3.5-7.7,4-12c0.1-0.4,0-0.9,0.5-1c0.6-0.2,0.9,0.3,1.2,0.6c2.5,2.3,3.9,1.9,4.8-1.4
	c0.5-1.9,0.5-4.3,3.3-4.5c1.6-3.6,5.5-6,5.1-10.6c-0.2-2.1,0.5-2.6,2.4-1.5c0.9,0.6,2,0.9,2.9,1.5c1.7,1.2,3,1.8,3.7-1
	c0.4-1.4,1.6-1.1,1.9,0c0.6,1.9,2,2.2,3.5,2.7c0.9,0.3,1.7,0.6,2.5,1.1c2.2,1.2,2.5,2.2,1.4,4.4c-0.4,0.9-1,1.8-1.1,2.8
	c-0.3,1.7,0.1,3.2,2.2,3.6c2.6,0.5,4.9,1.8,7.7,2c2.1,0.2,3.8,2.3,5.9,3c0.3,0.1,0.5,0.7,0.5,1.1c-0.1,2.4,0.3,4.6,1.4,6.7
	c0.2,0.5,0.1,1.1-0.5,1.2c-3.6,0.9-0.5,2.3-0.1,3.2c0.3,0.7,1.3,1,1.6,1.8c0.2,0.5,0.5,1,0,1.4c-0.6,0.5-1.1,0.2-1.5-0.3
	c-0.9-1.2-2.2-2.1-3.4-3c-0.7-0.5-1.5-1.2-2.2-0.2c-0.6,0.9,0.4,1.2,1,1.7c1.9,1.4,2.8,3.9,5.1,5c0.8,0.4,1.2,0.6,1.5-0.3
	c0.6-1.8,2.4-2,3.5-1.5c1.8,0.7,2.2,2.8,2.9,4.4c0.3,0.7-0.7,0.9-1.2,1c-7.5,1.5-14.9,3.4-22.5,4.7c-6,1-11.9,2.5-17.9,3.5
	c-11.7,1.9-23.5,3.6-35.3,5.4c-0.9,0.1-1.8-0.1-2.7-0.3c-1.2-0.1-2.3-0.2-3.5,0.2c-7,2.1-14.3,2.4-21.5,3.6
	C582.7,324.3,582.6,324.2,582.4,324z" />
              <radialGradient id="SVGID_37_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st36" d="M516,304.8c-0.2-1.3,0.5-2.4,1.3-3.7c2.9-4.5,6-9,2.6-14.7c-0.3-0.5-0.4-1.3-0.3-1.9
	c0.4-9.3-0.6-18.6-1.4-27.8c-0.4-4.6-0.8-9.1-1.1-13.7c-0.1-1.4,0.2-2.6,2.1-2c1.8,0.5,3-0.8,4.3-1.6c1.3-0.7,2.5-1.2,4-1.2
	c6.2,0.1,12.2-1.3,18.3-1.9c5.1-0.5,5.8-0.1,6.3,5c0.9,8.5,2.1,17,2.8,25.5c0.5,5.6,1.8,11,1.7,16.6c0,0.2,0.1,0.5,0,0.7
	c-0.1,1.1,1.4,2.2,0.2,3.2c-1.2,1.1-2.6,2.3-4.3,2.4c-2.1,0-2.8,1.1-2.9,2.9c0,1.3-0.8,2-1.7,2.8c-2,1.7-3.8,3.6-4.4,6.4
	c-0.4,2.1-2.2,1.6-3.4,1.1c-2.5-1-4.4-0.2-5,2.5c-0.2,1.1-0.6,2.1-1.6,0.8c-1.7-2.2-3-1.2-4.2,0.2c-1.2,1.3-2,2.5-3.8,0.6
	c-0.7-0.7-2.1-0.3-3.2-0.2c-1,0.2-1.9,0.5-2.9,0.7C516.3,308.3,516,308.1,516,304.8z" />
              <radialGradient id="SVGID_38_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st37" d="M731.2,165.7c-0.8,3.1-1.5,6.4-2.4,9.7c-0.1,0.3-0.3,0.6-0.6,0.7c-0.5,0.2-0.8-0.3-0.9-0.7
	c-0.2-1.3-1.1-1.8-2.2-2.2c-0.7-0.3-1.2-0.8-1.4-1.6c-2.3-9.1-5.7-17.9-8.3-27c-0.3-1-0.6-2.1-0.9-3.1c-0.3-0.9-0.5-1.7,1-1.4
	c0.8,0.2,1-0.4,1.1-1c0-3.7,1.4-7,3.3-10c0.9-1.4,0.8-2.6-0.1-4.2c-1.1-2-1.1-4.3,0.2-6.4c0.5-0.8,0.8-1.7,0.5-2.6
	c-0.7-2.7-0.2-5.2,0.9-7.8c1.3-3.1,2.4-6.2,3.6-9.3c0.2-0.6,0.4-1.4,1.1-1.5c0.9-0.2,1,0.7,1.3,1.2c1.5,2.4,2.5,2.4,4.6,0.5
	c1.2-1,2.5-1.9,3.8-2.9c0.5-0.4,1-0.5,1.6-0.4c4,0.5,6.1,2.5,7.2,6.4c1.8,6.2,3.8,12.4,5.6,18.7c0.4,1.4,1.3,1.9,2.6,1.7
	c2.6-0.3,3.4,1.1,3.7,3.3c0.1,0.8-0.1,1.8,0.6,2.4c0.8,0.7,1.4,2.8,3,0.7c0.3-0.4,0.9-0.2,1.4,0c3.2,1.5,4.1,7,1.2,9
	c-2.1,1.5-3.7,3.3-5.6,4.9c-1.2,1-2,2.2-4.2,1.2c-1.5-0.7-3.5,1-4.4,2.9c-1.1,2.3-1.7,2.4-3.5,0.5c-0.4-0.4-0.7-0.7-1.3-0.4
	c-0.5,0.2-0.9,0.7-0.6,1.2c0.7,1.4-0.2,2.8,0,4.2c0.3,2.2,0.1,4.1-2.6,4.5c-0.6,0.1-0.7,0.6-0.9,1.1c-1.1,2.5-3.3,3.8-6,3.6
	C732.4,161.5,731.2,163.2,731.2,165.7z" />
              <radialGradient id="SVGID_39_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st38" d="M617.8,346.4c2.7-0.5,4.5,1,5.6,3c0.9,1.5,2,1.7,3.3,1.5c3.5-0.6,7.1-0.8,10.6-1.3c1.7-0.2,3.1-0.1,4.5,0.8
	c5.4,3.3,10.4,7.2,15.5,10.9c1,0.8,0.9,1.2,0,2.1c-3.1,3-5.3,6.4-5.1,10.9c0.1,2.1-1.3,4-2.5,4.6c-3.5,1.7-4.5,5.5-7.3,7.7
	c-1.9,1.5-3.2,3.7-5.6,4.4c-0.7,0.2-0.9,0.6-0.9,1.3c-0.1,2.5-3.4,6.4-5.8,7c-1.3,0.3-2.4-0.1-2.8-1.4c-1-2.7-1.9-5.3-4.7-7
	c-1.4-0.9-1.8-3.4-2.6-5.3c-0.7-1.7-1.8-2.9-3.7-3.3c-1.6-0.4-2.9-1.5-3.6-3.2c-0.2-0.5-0.3-1.1-0.7-1.3c-3.2-2.2-5.4-5.7-9.1-7.2
	c-1.6-0.6-2.8-1.6-3.5-3c-1.7-3.6-3.7-6.7-8-7.7c-1.2-0.3-3.3-0.7-3.1-2.4c0.2-1.9,1.8-3.6,3.4-4.6c2.8-1.7,5.8-2.9,8.7-4.4
	c2.7-1.5,5.7-1,8.6-1.4C612,346.6,614.9,346.8,617.8,346.4z" />
              <radialGradient id="SVGID_40_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st39" d="M611.2,252.8c0.2,3.4,2.1,6.3,2.1,9.6c0,1.3,1,1.4,2,1.2c2.8-0.7,5.6-0.7,8.3-1.5c3.1-0.9,3.9-0.3,4.5,3
	c0.2,0.9,0.3,1.8,0.5,2.7c0.2,0.7,0,1.6,1,1.8c0.9,0.2,1.4-0.6,1.7-1.2c1.5-2.8,5-3.1,6.7-5.7c0.1-0.2,0.5-0.3,0.7-0.3
	c2.9,0.4,4.9-1.2,6.8-2.9c0.1-0.1,0.3-0.3,0.4-0.3c2.1,0.3,3.7,1.8,5.3,3c0.9,0.7,1.2,1.8,0.9,2.9c-0.3,1-0.7,1.5-1.8,0.8
	c-1.5-0.9-3-1.7-4.6-2.5c-1.5-0.8-2.6-0.4-2.4,1.4c0.5,4.7-2,8.4-5,10.7c-2.4,1.9-2.7,4-3.6,6.2c-0.8,1.9-1.6,2.1-3.1,0.5
	c-0.6-0.7-1.2-1.5-2.3-1.1c-1.2,0.3-1,1.4-1.1,2.3c-0.5,4.8-3,8.9-4.7,13.3c-0.2,0.5-0.6,0.9-0.6,1.4c0.5,5.1-3.4,5.6-6.8,6.5
	c-1,0.3-1.2,1.1-1.7,1.7c-1,1.4-2.3,2.5-4.2,1.6c-1.1-0.5-1.8-0.1-2.4,0.6c-1.7,2-4.1,1.7-5.3,0.3c-2.7-3.3-6.9-5.1-9.1-8.8
	c-0.9-1.5-1.8-3-3.2-4.4c-1.5-1.5-0.8-3.5,1.1-4.5c2.2-1.1,2.6-3.4,3.1-5.5c0.2-0.9,0-2,0.3-3c0.3-1.1-0.1-3.4,2.6-1.7
	c1.2,0.8,2.2-0.5,2.1-2.1c-0.3-4.4,0.7-5.5,4.8-7.4c3.3-1.6,5.1-4.7,5.7-8.3C610.7,259.8,610.9,256.3,611.2,252.8z" />
              <radialGradient id="SVGID_41_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st40" d="M474.3,155.7c2.1-1,4.1-2,5.8-3.7c0.7-0.7,1.6-0.8,2.5-1c3.6-0.7,6.3-2.6,8.8-5.3c2.3-2.5,4.5-5.1,7.5-6.8
	c1.6-0.9,3.2-1,5.2-0.6c-3.8,2.6-6.5,5.6-7.7,9.7c-0.2,0.6-0.7,1.3,0,1.9c0.8,0.6,1.2-0.3,1.6-0.7c2.4-2,7.4-1.4,9,1.3
	c1.7,2.9,3.6,5.1,7.4,3.6c0.3-0.1,0.8,0.1,1.1,0.3c2,1.2,4,0.6,5.5-0.7c3-2.8,6.3-4.5,10.5-4.1c1.7,0.2,3.1-1.1,4.7-1.6
	c1.6-0.5,2.3-0.3,2.3,1.6c0.1,2.4,1.5,3.5,4,3c1.8-0.4,3.5-1.1,5.2-1.7c1.4-0.6,2.4-0.4,2.2,1.3c-0.2,2.8,0.5,5.1,2.9,6.9
	c-5.4,0.1-10.6,2.7-15.9,0.5c-1.5-0.6-3-0.3-4.2,1.3s-3.3,1.3-5.1,1.5s-3.5,0.3-3.8,2.7c-0.1,0.7-0.7,1.2-1.2,0.8
	c-1.5-1.2-2.6-0.2-3.9,0.4c-0.4,0.2-0.9,0.5-1.1,0.4c-3-1.1-3.2,1.3-3.9,3c-1.1,2.6-1.6,5.4-3.2,7.9c-0.3,0.5-0.4,1.4-1.3,1.2
	c-0.7-0.1-1.1-0.8-1.3-1.5c-0.4-2-0.8-4.1-1.1-6.1c-0.1-0.5,0.1-1.3-0.2-1.4c-2.2-1.5-3.7-4.1-6.3-4.7c-3.7-0.9-7.5-1-11-2.6
	c-2.5-1.2-5.5-1.4-8.3-1.7C478.3,159.9,475.6,159.1,474.3,155.7z" />
              <radialGradient id="SVGID_42_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st41" d="M629.9,268.1c-0.9-1.6-0.7-3.3-1.1-4.9c-0.3-1.1,0.2-1.7,1.4-1.9c2.5-0.5,5-1.3,7.6-1.5c1.7-0.1,3.4-0.4,5-1.3
	c2-1.2,4.3-0.6,6.4-0.9c8.4-1.1,16.6-3.6,25-5c2.1-0.4,2.9,0.3,3.4,2.1c1.4,5.6,3.6,11.1,4.4,16.9c0.2,1.5,1.1,1.7,2.2,1.7
	c1.9-0.1,3.8-0.3,5.7-0.6c1.6-0.3,1.9,0.4,1.9,1.7c0,3.9-0.6,4.7-4.3,5.8c-0.2,0-0.4,0-0.5,0.1c-2.4,1.9-3.7,0.9-4.6-1.6
	c-0.4-1.1-1.3-1.3-2.4-1.1c-4.2,0.6-5.8-1.8-4.2-5.8c0.2-0.5,0.3-1.2-0.2-1.3c-2.2-0.8-1.3-1.7-0.5-3.2c0.6-1,0.2-2.8-0.7-3.5
	c-2.2-1.8-0.7-3.2-0.1-4.8c0.2-0.6,1-0.9,0.6-1.7c-3.7,1.3-5.2,4.1-4,7.3c0.5,1.4,0,2.8-0.3,4.2c-0.5,2.6,0.6,4.5,1.9,6.5
	c0.2,0.3,0.7,0.6,0.8,0.9c0.5,1.3,1.6,2.6,0.8,3.9c-0.8,1.2-2.2,0.1-3.2,0c-2.1-0.2-4.3-0.5-6.4-0.9c-2.4-0.4-3.2-1.8-1.7-3.7
	c1.3-1.6,1.3-3.7,2.6-5.3c0.6-0.6,0.5-1.6,0-2.4c-0.6-0.8-1.4-0.7-2.3-0.6c-2.9,0.6-4.7-0.3-6.9-2.6c-2.5-2.6-5.5-5.3-9.3-6.4
	c-1.2-0.3-2.3,0-3.2,0.9c-0.6,0.6-1.2,1.6-1.8,1.6c-2.9,0-4.8,1.7-7.1,3.1C632.9,265.1,632,267.1,629.9,268.1z" />
              <radialGradient id="SVGID_43_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st42" d="M710.4,154.3c-0.1,2.4-1.9,3.4-3.3,4.6c-0.9,0.7-1.7,1.3-1.3,2.6c1.1,3.6,0.5,7.3,0,10.9
	c-0.5,3.4-0.8,6.7,0.4,10.1c0.6,1.8-0.5,3.6-0.3,5.4c0,0.6-0.6,0.8-1.1,0.9c-1.6,0.4-3.2,0.9-4.8,1.2c-1.3,0.3-1.9-0.3-2.2-1.7
	c-0.4-2.5-0.9-4.9-1.6-7.3c-0.5-1.8-0.3-4.2-3-4.7c-0.2,0-0.6-0.6-0.5-0.8c0.7-2.4-1.1-4.1-1.8-6.1c-0.2-0.5-0.5-1-0.4-1.4
	c1.3-4.6-1.3-8.5-2.3-12.7c-0.7-3-0.4-3.4,2.5-4.2c5.2-1.3,10.5-2.6,15.7-3.9c1.7-0.4,2.7-0.2,2.4,1.8
	C708.5,151.1,710.2,152.6,710.4,154.3z" />
              <radialGradient id="SVGID_44_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st43" d="M707.3,166.1c0-0.4,0.1-0.9,0-1.2c-0.8-3.1,0.2-5.3,2.7-7.2c1.9-1.4,1.8-3.6,0.8-5.5c-1.7-3.1-1-6.3-0.5-9.5
	c0.1-0.8,0.5-1.4,1.5-1.3c0.9,0.1,1.8,0.1,2.1,1.3c0.7,2.5,1.5,4.9,2.2,7.4c2.2,7.5,5.1,14.8,6.8,22.4c0.2,0.8,0.7,1.4,1.4,1.6
	c2,0.5,2.1,2.8,3.6,3.9c0.6,0.4,0.1,1.5-0.5,1.9c-2.1,1.3-3.7,3-5.3,4.8c-1.2,1.4-3.3,1.1-4.9,1.6c-2.4,0.7-4.8,1.3-7.3,1.8
	s-3.2-0.2-2.8-2.7c0.2-1.6,0-3-0.5-4.6c-1-3.3-0.2-6.6,0.3-9.9C707.2,169.2,707.3,167.7,707.3,166.1z" />
              <radialGradient id="SVGID_45_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st44" d="M697.8,197.1c0-6,0-6.1,5.8-6.9c6.4-0.9,12.5-2.7,18.8-4.1c0.6-0.1,1.1-0.3,1.2-1c0.2-1,0.9-1.8,1.7-2.3
	c0.7-0.5,1.3-2,2.4-1c1,1,2.3,2.1,1.6,3.9c-0.4,1-0.9,2-1.2,3c-0.6,2,0.3,3.2,2.4,3.1c1.1-0.1,1.9,0.4,2.6,1.2
	c0.9,1.2,1.8,2.4,2.6,3.7c1.9,2.9,3.9,3.3,6.6,1.3c0.6-0.4,1-1.3,1.9-0.8c0.2,0.1,0.4,0.6,0.4,0.9c-0.2,0.8-1,0.8-1.6,1
	c-2,0.7-3.9,1.4-5.3,3.1c-0.4,0.5-0.8,0.7-1.5,0.1c-1.5-1.4-3-0.9-3.7,1.3c-0.2,0.6-0.1,1.2-0.8,1.3c-0.7,0.2-1-0.4-1.4-0.8
	c-1.4-1.9-3.3-3.3-4.3-5.5c-0.5-1.1-1.5-1.4-2.7-1.1c-7.3,1.7-14.6,3.3-21.8,5.1c-3.2,0.8-3.6,0.7-3.6-2.7
	C697.8,199,697.8,198.1,697.8,197.1z" />
              <radialGradient id="SVGID_46_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st45" d="M691.8,262.2c-0.5-0.4-0.5-0.8-0.4-1.2c0.7-2-0.1-2.6-2.1-2.8c-3.2-0.2-5.8-1.9-7.8-4.5c-1.5-2,0-3.9,1.3-4.7
	c2.9-1.8,3.6-5.4,6.3-7.3c1.1-0.8,0.2-1.9-0.7-2.4c-2.5-1.4-3.7-4.2-6.2-5.6c-0.7-0.4-1-1.7-0.4-2.5c0.9-1.1,1.1-2.2,0.4-3.4
	c-0.4-0.8-0.1-1.5,0.4-2.1c0.6-0.7,0.9-1.6,1.1-2.5c0.7-3.3,1.1-3.6,4.1-2.2c1.6,0.7,3.3,1,5,1.5c3.7,1.1,4.6,3.4,2.7,6.7
	c-1.9,3.4-1.7,3.9,1.8,4.8c1.5,0.4,1.5,1.7,1.5,2.8c0.3,6,0.9,12-3.1,17.2c-1.1,1.5-1.9,3.3-2.1,5.3
	C693.5,260.5,692.9,261.7,691.8,262.2z" />
              <radialGradient id="SVGID_47_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st46" d="M721.4,209.3c0.2,1.1-0.5,2.2-2.2,2.8c-2.3,0.9-4.6,1.9-7.1,2.2c-3.6,0.4-5.6,3.4-8.3,5.2
	c-1,0.7-2,1.4-3.1,2.1c-0.6,0.4-1.4,0.2-1.1-0.6c1.6-4.7,0.3-9.2-0.6-13.7c-0.5-2.2,0.2-3.2,2.4-3.7c5.3-1.1,10.5-2.5,15.7-3.7
	c1.2-0.3,2.2,0.1,2.6,1.3C720.5,203.6,721.3,206.1,721.4,209.3z" />
              <radialGradient id="SVGID_48_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st47" d="M277.2,532.7c0.7-3.5-0.3-6.5-2-9.3c-0.6-0.9-0.5-1.7,0.4-2.4c0.6-0.5,1.2-1.2,1.8-1.8c1-1,1.6-2.1,0.5-3.5
	c-0.5-0.6-0.8-1.4-0.1-2c0.7-0.6,1.2,0,1.8,0.3c2.7,1.2,5.4,2.5,8.2,3.7c1.2,0.5,2.2,1.3,2.7,2.5c1,2.1,1.8,4.3,4.1,5.5
	c1,0.5,0.4,1.3-0.2,1.8c-1.5,1.5-3.3,2.6-5.4,2.9c-2.8,0.3-4.6,1.8-6,3.9c-0.7,1-1,2.5-2.7,1.9C278.5,535.5,277.2,534.6,277.2,532.7
	z" />
              <radialGradient id="SVGID_49_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st48" d="M684.7,272.2c-1.1,0-1.6-0.8-1.8-1.7c-1.2-5.8-3-11.4-4.6-17.1c-0.2-0.8-0.6-1.6,0.1-2.4
	c0.5-0.6,1.1-1.7,1.9-1.3c1,0.5,0,1.3-0.2,1.9c-0.5,2,0.1,3.8,1.6,5c1.8,1.4,2.9,3.2,3.7,5.2c0.8,1.8,1.9,3.1,3.5,4.1
	c0.5,0.4,1.1,0.6,1.5,1.2c1.9,3.2,1.3,4.4-2.4,4.7C686.8,271.9,685.7,272.1,684.7,272.2z" />
              <radialGradient id="SVGID_50_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st49"
                  d="M729.7,205.6c-0.1,0.2-0.3,0.7-0.5,0.7c-2.2,0.3-2.9,2.6-4.7,3.5c-1.4,0.7-2.2,0.7-2.2-1.2
	c0-2.5-0.7-4.9-1.5-7.3c-0.3-0.8-0.7-1.7,0.4-2.2c1.1-0.5,2.6-1.4,3.5-0.4c1.8,1.8,3.2,4,4.7,6C729.5,205,729.6,205.3,729.7,205.6z" />
              <radialGradient id="SVGID_51_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st50"
                  d="M689.8,280.6c0.3,0.8,0,1.2-0.3,1.6c-1.8,1.6-2.2,3.7-2.1,5.9c0.1,2.4-0.2,4.8-1.1,7.1
	c-0.3,0.8-0.6,1.8-1.5,1.7c-1-0.1-1.4-1.2-1.4-2.1c-0.1-2.7,0-5.3,1.2-7.8c0.5-1,1.1-2,1.2-3C686,281.2,687.8,280.9,689.8,280.6z" />
              <radialGradient id="SVGID_52_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st51" d="M270.1,507.1c-1.6,0.2-2.4-0.8-3.2-1.9c-0.8-1.1-1.8-2-2.6-3c-0.4-0.5-0.5-1.2,0-1.9c0.3-0.5,0.9-0.7,1.3-0.3
	c2.2,1.9,5.2,1.3,7.5,2.6c0.8,0.5,2.1,0.7,2,2c-0.1,1.2-1.1,1.5-2.1,1.9C272,506.7,271.1,507.2,270.1,507.1z" />
              <radialGradient id="SVGID_53_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st52" d="M246.9,494.6c-3.2,1-7.2-0.5-8.5-2.8c-0.7-1.2-0.6-2.1,0.7-2.5c1.2-0.4,2.4-2.3,3.8-0.3
	C244.1,490.8,244.9,493,246.9,494.6z" />
              <radialGradient id="SVGID_54_" cx="396.2129" cy="323.7752" r="357.3538" gradientTransform="matrix(1 0 0 -1 0 612)" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st53" d="M216.9,478.6c1.4,0,2.9,0.1,2.6,2c-0.2,1.5-0.5,3.3-2.6,3.4c-1.8,0.1-4.6-1.7-4.6-2.7
	C212.5,479.8,214.5,478.6,216.9,478.6z" />
              <radialGradient id="SVGID_55_" cx="245.2303" cy="235.4866" r="209.0495" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st54" d="M30,219.5c1.3,2.9,2.3,6.2,1,9c-1.5,3.1-1.9,6.3-2.4,9.6c-0.2,1.2,0,2.2,0.6,3.3c1.4,2.5,2.7,5,4.1,7.5
	c0.9,1.7,1.9,3.5,1.5,5.4c-0.7,3,0.4,5,2.4,6.8c0.6,0.5,0.9,1.7,1.8,1.4s0.2-1.3,0.5-2c0.1-0.4,0.2-0.8,0.4-1.2
	c0.4-0.7,0.8-1.3,1.7-1.3c0.7,0.1,1,0.7,1.2,1.2c0.3,0.8,0.3,1.6-0.6,1.9c-1.2,0.4-0.7,1.2-0.5,2c0.4,1.5,0.8,3,1.1,4.6
	c0.1,0.8,0.3,1.9-0.7,2.2c-0.9,0.3-1.4-0.6-2-1.2c-1.2-1.1-0.5-2.6-1-4c-1.9,3.3-2.5,6.7-0.8,10.3c0.8,2.3,1.5,4.7,4.7,5.3
	c1.4,0.3,1.5,2.3,1.1,3.7c-0.3,1.2-1.1,2.2-2,3.1c-1.5,1.3-2,2.8-1.6,4.3l53.3-15.3c-4.4-6.4-8.7-12.9-13.1-19.3
	c-1.9-2.8-2.8-5.5-1.9-8.9c1.8-6.7,3.4-13.4,5.1-20.1c2.1-8.5,4.3-16.9,6.5-25.4c0.4-1.7-0.2-2.5-1.8-2.8c-3.6-0.6-7-1.8-10.4-2.8
	c-11.8-3.4-23.6-6.6-35.4-9.9c-5-1.4-5.1-1.2-5.3,4c-0.2,6.8-3.1,12.5-7.9,17.2c-2.2,2.2-2.8,4.9-1.4,7.6
	C28.8,216.9,29.4,218.2,30,219.5z" />
              <radialGradient id="SVGID_56_" cx="366.2627" cy="324.528" r="301.3964" gradientUnits="userSpaceOnUse">
                <stop offset="0" style={{stopColor:"#F1F0F0"}} />
                <stop offset="1" style={{stopColor:"#DEDDDD"}} />
              </radialGradient>
              <path className="st55" d="M139.6,352c-2.1-4.3-3.8-8.7-4.5-13.5c-0.1-0.4-0.4-0.8-0.6-1.1c-9.5-14.3-18.9-28.6-28.4-42.9
	c-4.1-6.1-8.2-11.3-12.4-17.4l-53.3,15.3c0.2,0.7,0.5,0.5,1.1,1.2c1.2,1.5,2.2,3.2,2.6,5.1c0.7,3.5,1.9,6.8,3.9,9.9
	c3.2,4.9,5.1,10.1,2.4,16c-0.2,0.4-0.2,0.8-0.3,1.2c-0.5,1.8,0.7,3.2,1.9,3.3c3.4,0.3,6,2.4,9.1,3.2c3.8,1,5.8,3.3,7.3,6.5
	c1.1,2.3,2.9,3.6,5.6,3.8c1.7,0.1,3.4,0.5,3.8,2.8c0.5,2.6,1.4,3.5,3.9,5c0.3,0.2,0.6,0.5,0.9,0.8c4.7,5.4,9.1,10.8,8.1,18.8
	c-0.4,3.6,0.5,4.1,4.1,4.5c9,1.1,18,2.3,27,3.4c2.3,0.3,4.6,0.7,6.9,0c1.2-0.4,1.7-1.3,1.9-2.4c0.2-1.1-0.6-1.4-1.5-1.6
	c-1-0.2-1.2-0.9-1.1-1.9c0.2-1.2,0.2-2.5,0.3-3.7c0-1,0.2-1.9,1.2-2.3c2.1-0.8,2.9-2.6,3.2-4.7c0.6-3.4,1.9-6.3,5.7-7.4
	C139.3,353.6,140.1,353,139.6,352z" />
            </svg>
)