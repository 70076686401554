export default {
    main: "At RadNet, we remain dedicated to every one of our patients and to helping you get through this unprecendented time while still caring for all of your essential healthcare needs.",
    needs: [
        {name: "Availability", content: "Our imaging centers will remain open to serve our patients and communities during this critical time", image: "pin.svg"},
        {name: "Safety", content: "Our imaging centers will remain open to serve our patients and communities during this critical time", image: "shield.svg"},
        {name: "Service", content: "Our imaging centers will remain open to serve our patients and communities during this critical time", image: "mri.svg"},
    ],
    together: {
        title: "Together We Are Creating A Safe Environment",
        content: "Your health is our priority and we have implemented several enhanced saftey measures to keep you safe, while still delivering the highest standard of care.",
        image: "safe-env.png",
        icons_section: [
            {name: "Patient Screening", content:"If you have been diagnosed with COVID-19 and are still experiencing symptoms, please contact us.", image:"cough.svg"},
            {name: "Social Distancing", content:"To keep all patients and our staff safe, we have rearranged our waiting room areas to support social distancing.", image:"people.svg"},
            {name: "Hand Washing", content:"We encourage you to wash your hands before and after checking into your appointment.", image:"wash.svg"},
            {name: "Flexible Waiting Room Options", content:"Many of our centers offer the ability to register and check-in for your appointment outside of our facilities. This allows you to limit your time within our facility only to what’s necessary.", image:"phone.svg"},
            {name: "Continuous Monitoring", content:"Our leadership team is constantly monitoring and is aware of what is transpiring during this unprecedented crisis.", image:"care.svg"},
            {name: "Face Covering", content:"During your appointment, our staff will be wearing masks and we ask that you wear a face covering as well. If you do not have a face covering and our supply allows, we will provide you with one.", image:"mask.svg"},
            {name: "Enhanced Cleaning & Disinfecting", content:"Our imaging centers are always clean, but we have increased our standard cleaning protocols. Throughout the day, we are disinfecting high-touched surfaces with EPA approved sanitizer. We are taking the time in between each exam to ensure the cleanliness of all equipment.", image:"wipe.svg"},
            {name: "Knowledgeable & Prepared Staff", content:"Our staff has been trained extensively on infection prevention protocols which include comprehensive patient screening and utilizing personal protective equipment (PPE) when providing patient care.", image:"clipboard.svg"},
            {name: "Limit Patient & Staff Exposure", content:"Our staff has been trained extensively on infection prevention protocols which include comprehensive patient screening and utilizing personal protective equipment (PPE) when providing patient care.", image:"nocrowd.svg"},

        ]
    },
    remoteSimple: {
        title: "Our Remote Check-in Process Is Simple",
        image: "remote.png",
        steps: [
            "Please notify us upon your arrival by sending a text from outside of the center or from the comfort of your vehicle.",
            "We will text back and include links to complete check-in from outside the center."
        ]
    },
    covidImpact: {
        title: "The Covid-19 Impact",
        stats: [
            {name: "8,280", content: "Please notify us upon your arrival by sending a text from outside of the center or from the comfort of your vehicle."},
            {name: "45%", content: "Please notify us upon your arrival by sending a text from outside of the center or from the comfort of your vehicle."},
            {name: "500", content: "Please notify us upon your arrival by sending a text from outside of the center or from the comfort of your vehicle."}
        ],
        image: "covid-impact.png"
    },
    wellBeing: {
        title: "Our Main Goal is the Well-being of Our Patients and Team Members.",
        content: "At RadNet, the health and well-being of our patients and staff members remain our top priority. Our centers remain open to provide essential medical imaging services for physicians, patients, and our community. Rest assured, we follow strict CDC protocols for cleaning and disinfecting our equipment and centers. In addition, our staff uses personal protective equipment to protect patients and themselves.",
        images: ["","",""]
    },
    radnetDifference: {
        title: "The RadNet Difference",
        content: "RadNet has been delivering high-quality, cost-effective healthcare for over 35 years, leading the industry as the largest provider of outpatient imaging services in the country. With over 340 centers in 6 states, RadNet remains true to a mission that is patient-centered and committed to the future of healthcare."
    }
}
