import styled from "styled-components"

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "#AF0000" : "white"};
  color: ${props => props.primary ? "white" : "#AF0000"};

  font-size: 1em;
  font-weight: bold;
  margin: 1em;
  padding: 1em 2em;
  border-radius: 50px;
  cursor: pointer;

  &:hover {
    background: #940101;
  }
`;
export default Button
