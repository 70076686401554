import React, {useEffect, useState, useRef} from 'react' 
import {Row, Card, Container, Col} from 'react-bootstrap'
let token = 'f7b4614363a386511e692fa347543a98'//process.env.GATSBY_VIMEO_TOKEN
 
const VideoSection = () => {
    //get showcases from Vimeo
    let [playlists, setPlaylists] = useState([])
    let [currentPlaylist, setCurrentPlaylist] = useState('')
    let [videos, setVideos] = useState([])
    let [currentVideo, setCurrentVideo] = useState([])
    let [showMore, setShowMore] = useState(false)
    let videosArea = useRef()

    let urlBase = "https://api.vimeo.com/me"
    let getVideos = (url) => {
        let apiUrl = urlBase + url
        setVideos([])
        fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
            }).then(data => data.json()).then(res => {
                console.log(res)
                setCurrentVideo(res.data[0])
                setVideos(res.data)
                
            })
        
    }

    let scrollToPlayer = (video) => {
        if(videosArea.current){

            setCurrentVideo(video)
            videosArea.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }

    useEffect(()=>{
        let url = "https://api.vimeo.com/me/albums"
        console.log(url, token)
        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
            }).then(data => data.json()).then(res => {
                let playlists = res.data
                playlists = playlists.filter(item => item.privacy.view === 'anybody' 
                && item.metadata.connections.videos 
                && item.metadata.connections.videos.total > 10 
                && item.description
                && item.name.length < 25).slice(0,9)
                setPlaylists(playlists)
                let defaultPlaylist = playlists[0]
                setCurrentPlaylist(defaultPlaylist.name)
                getVideos(defaultPlaylist.metadata.connections.videos.uri)

            })
    },[])

    let menu = playlists
    
    let Video = ({uri, width="720", height="540", cols="4"}) => { 
        return (
            <Col md={cols}>
                <iframe className="player" src={`https://player.vimeo.com/video/${uri}?title=0&byline=0&portrait=0`} width={width} height={height} frameBorder="none" />
            </Col>
            )
    }

    let player = (video) => {
        
        let uri = video.uri.split(`/videos/`)[1]
        return <Video uri={uri} cols="8" />
    }
    
    let sideRail = videos.slice(1,2).map(item => {
        let picture = item.pictures.sizes[3]
        return (
            <Col md="4">
                <Card onClick={()=>scrollToPlayer(item)}>
                    <Card.Img variant="top" className="video-thumb" src={picture.link} height={`${picture.height}`} width={`${picture.height}`} />
                    <div>
                        {item.tags ? item.tags.map(tag => <span className="tag">#{tag.name}</span>) : ''}
                    </div>
                    <Card.Title>{item.name}</Card.Title>
                </Card>
            </Col>
        )
    })
    
    let bottomRail = videos.slice(2, 5).map((item, idx)=>{
        let picture = item.pictures.sizes[3]

        return (
            <Col md="4" key={idx}>
                <Card onClick={() => scrollToPlayer(item)}>
                <Card.Img variant="top" className="video-thumb" src={picture.link} height={`${picture.height}`} width={`${picture.height}`} />
                
                    <div>
                        {item.tags ? item.tags.map(tag => <span className="tag">#{tag.name}</span>) : ''}
                    </div>
                    <Card.Title>{item.name}</Card.Title>
                </Card>
            </Col>
        )
    })

    //todo refactor
    let moreVideos = videos.slice(5).map((item, idx) => {
        let picture = item.pictures.sizes[3]

        return (
            <Col md="4" key={idx}>
                <Card onClick={() => scrollToPlayer(item)}>
                <Card.Img variant="top" className="video-thumb" src={picture.link} height={`${picture.height}`} width={`${picture.height}`} />
                
                    <div>
                        {item.tags ? item.tags.map(tag => <span className="tag">#{tag.name}</span>) : ''}
                    </div>
                    <Card.Title>{item.name}</Card.Title>
                </Card>
            </Col>
        )
    })

    return (
        <div className="video-section">

            <Row className="menu text-center mt-5" ref={videosArea}>
                {menu.map(item => (
                <Col md="2" key={item.name}>
                    <button className={`btn btn-outline-dark mt-2 ${item.name === currentPlaylist ? 'active': ''}`} onClick={e=>{
                        try {
                            let url = item.metadata.connections.videos.uri ?? ''
                            if(url !== ''){
                                setShowMore(false)
                                setCurrentPlaylist(`${item.name}`)
                                getVideos(url)
                            }
                        }catch(e){
                            console.log(`The playlist is not available`)
                        }
                    }}>{item.name}</button>
                </Col>
                ))}
            </Row>
            {videos.length > 0 ? 
            (
            <>
                <Row className="mt-5 mb-5">
                    {player(currentVideo)}
                    {sideRail}
                </Row>
                <Row className="mt-3">
                    {bottomRail}
                </Row>
                <Row className="mb-5">
                    {/* on click load more videos for this playlist */}
                    {!showMore ? <Col className="text-center"><button className="btn btn-primary" onClick={()=>setShowMore(true)}>Load More</button></Col> : ''}
                    {showMore ? moreVideos : ''}
                </Row>
                <Row>
                    
                </Row>
            </>) : (<Row className="mt-5 text-center">
                <Col>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                </Col>
            </Row>)}
        </div>
    )
}

export default VideoSection