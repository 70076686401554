import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Map from "../components/map"
import Together from "../components/togetherWeAre"
import Footer from "../components/footer"
import {Container, Row, Col} from 'react-bootstrap'

import HereForYouPageComponent from '../components/HereForYouPage'


const HereForYouPage = ({path}) => {
  useEffect(()=>{
    const script = document.createElement('script');
    script.src = 'https://connect.podium.com/widget.js#API_TOKEN=5f418899-1267-4d4e-afac-3f257b3bef5a'
    script.async = true;
    script.dataApiToken = "5f418899-1267-4d4e-afac-3f257b3bef5a";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  },[]);


  return(
  <Layout path={path}>
    <SEO title=" " />

    <HereForYouPageComponent />
    <Footer />
   </Layout>
   )
}

export default HereForYouPage
